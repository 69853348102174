import { Button } from 'components/atoms';
import fontService, { FontWeight } from 'services/FontService';
import styled from 'styled-components';

export const ContentWrapper = styled.div`
  width: 400px;
`;

export const Subtitle = styled.p`
  ${fontService.getFont({ size: 14, lineHeight: 143, weight: FontWeight.medium })};
`;

export const SelectWrapper = styled.div`
  margin-top: 8px;
`;

export const ConfirmButton = styled(Button)`
  margin-top: 32px;
`;
