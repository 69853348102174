export enum TablesStatus {
  Open = 'open',
  Inactive = 'Inactive',
  Maintenance = 'Maintenance',
  Taken = 'taken',
}

export enum TablesClosingStatus {
  Inactive = 'Inactive',
  Maintenance = 'Maintenance',
}
