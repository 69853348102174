import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { ClientSideNotifications } from 'types';
import { socketConnectedAction } from 'data/reducers';

export function* onSocketConnectedSaga(notification: string) {
  try {
    if (notification === ClientSideNotifications.SOCKET_CONNECTED) {
      yield put(socketConnectedAction());
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* onChatSocketConnectedSaga(notification: string) {
  if (notification === ClientSideNotifications.SOCKET_CONNECTED) {
    yield;
  }
}
