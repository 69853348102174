import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';

import {
  initReducer,
  authReducer,
  successReducer,
  errorReducer,
  tablesReducer,
  gameReducer,
  websocketReducer,
  loadingReducer,
} from 'data/reducers';

export const rootReducer = combineReducers({
  init: initReducer,
  error: errorReducer,
  success: successReducer,
  loading: loadingReducer,
  form: reduxFormReducer,
  auth: authReducer,
  tables: tablesReducer,
  game: gameReducer,
  webSocket: websocketReducer,
});
