import { css } from 'styled-components';
import { ResultBall } from 'types';

export const ballStyles = {
  default: {
    [ResultBall.BLUE]: css`
      background: radial-gradient(
          31.25% 31.25% at 68.75% 37.5%,
          #ededed 0%,
          #41b6e6 47.4%,
          #008dc7 100%
        ),
        radial-gradient(31.25% 31.25% at 68.75% 37.5%, #ededed 0%, #2b2a2b 47.4%, #000000 100%);
    `,
    [ResultBall.RED]: css`
      background: radial-gradient(
        31.25% 31.25% at 68.75% 37.5%,
        #ffc1cb 0%,
        #c8102e 47.4%,
        #880016 100%
      );
    `,
    [ResultBall.GOLD]: css`
      background: radial-gradient(
          31.25% 31.25% at 68.75% 37.5%,
          #fff5b9 0%,
          #fedb00 47.4%,
          #d5b700 100%
        ),
        #fedb00;
    `,
    [ResultBall.UNDEFINED]: css``,
  },
};
