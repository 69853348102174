import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { Notifications } from 'types';
import { setStageJackpot } from 'data/reducers/AuthReducer';
import { onJackpotStageStartedData } from './types';

// eslint-disable-next-line require-yield
export function* onJackpotStageStartedSaga({
  type: notificationType,
  data: result,
}: onJackpotStageStartedData) {
  try {
    const { players, stageNumber } = result;
    if (notificationType === Notifications.JACKPOT_STAGE_STARTED) {
      yield put(setStageJackpot({ players, stageNumber }));
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
