import React, { ReactNode, memo } from 'react';
import { ButtonContainer } from './styles';
import { ButtonPreloader } from '../Preloader';

export enum ButtonVariants {
  Default = 'Default',
  Accept = 'Accept',
  Cancel = 'Cancel',
  Error = 'Error',
  Info = 'Info',
}

export enum ButtonSizes {
  sm = 'sm',
  md = 'md',
  lg = 'lg',
}

export interface IButton {
  variant?: ButtonVariants;
  type?: 'submit' | 'reset' | 'button';
  onClick?: () => void;
  children: ReactNode;
  disabled?: boolean;
  size?: ButtonSizes;
  loading?: boolean;
  className?: string;
  sidePaddings?: number;
}

export const Button = memo(
  ({
    variant = ButtonVariants.Default,
    disabled = false,
    loading = false,
    onClick,
    children,
    type,
    sidePaddings = 24,
    size = ButtonSizes.md,
    className,
    ...props
  }: IButton) => (
    <ButtonContainer
      variant={variant}
      className={className}
      disabled={disabled || loading}
      sidePaddings={sidePaddings}
      loading={loading}
      size={size}
      onClick={onClick}
      type={type}
      {...props}>
      {loading ? <ButtonPreloader /> : children}
    </ButtonContainer>
  ),
);
