import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { ClientSideNotifications } from 'types';
import { socketDisconnectedAction } from 'data/reducers';

export function* onSocketDisconnectedSaga(notification: string) {
  try {
    if (notification === ClientSideNotifications.SOCKET_DISCONNECTED) {
      yield put(socketDisconnectedAction());
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* onChatSocketDisconnectedSaga(notification: string) {
  if (notification === ClientSideNotifications.SOCKET_DISCONNECTED) {
    yield;
  }
}
