import * as React from 'react';
import { memo } from 'react';

import ContentLoader from 'react-content-loader';

export const ButtonPreloader = memo((props) => (
  <ContentLoader
    speed={1}
    width={44}
    height={8}
    viewBox="0 0 44 8"
    backgroundColor="#fff7eb"
    backgroundOpacity={0.6}
    foregroundColor="#fff7eb"
    foregroundOpacity={0.2}
    {...props}>
    <circle cx="4" cy="4" r="4" />
    <circle cx="22" cy="4" r="4" />
    <circle cx="40" cy="4" r="4" />
  </ContentLoader>
));
