import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  confirmResultSuccessAction,
  changeGameStateAction,
  changeGameStatusAction,
} from 'data/reducers';
import { Notifications, ResultConfirmedResponse, TableStates } from 'types';
import { gameStatus } from 'constants/common';

export function* resultConfirmed({
  data: result,
  type: notificationType,
}: ResultConfirmedResponse) {
  try {
    if (notificationType === Notifications.RESULT_CONFIRMED) {
      const { winner } = result;
      yield put(confirmResultSuccessAction({ winner }));
      yield put(changeGameStateAction({ tableState: TableStates.ResultConfirmed }));
      yield put(changeGameStatusAction({ status: gameStatus.DisplayingWin }));
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
