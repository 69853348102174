import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  bettingTimeStartedAction,
  changeGameStateAction,
  changeGameStatusAction,
  changeTimeLeftAction,
} from 'data/reducers';
import { BettingTimeStartedResponse, Notifications, TableStates } from 'types';
import { gameStatus } from 'constants/common';

export function* bettingTimeStarted({
  type: notificationType,
  data: result,
}: BettingTimeStartedResponse) {
  try {
    if (notificationType === Notifications.BETTING_TIME_STARTED) {
      const { bettingTime } = result;
      const startedAt = new Date().toISOString();

      yield put(changeGameStatusAction({ status: gameStatus.BettingTime }));
      yield put(changeGameStateAction({ tableState: TableStates.BettingTimeStarted }));
      yield put(bettingTimeStartedAction({ bettingTime, startedAt }));
      yield put(changeTimeLeftAction({ bettingTimeLeft: bettingTime }));
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
