import { ActionType, createAction, createReducer } from 'typesafe-actions';

import { ACTIONS } from '../constants';

interface IInitState {
  appInitialized: boolean;
}

export const initAppRequstAction = createAction(ACTIONS.INIT_APP_REQUEST)();
export const initAppSuccessAction = createAction(ACTIONS.INIT_APP_SUCCESS)();
export const initAppFailureAction = createAction(ACTIONS.INIT_APP_FAILURE)();

export const defaultState: IInitState = {
  appInitialized: false,
};

const actions = {
  initAppSuccessAction,
};

export const initReducer = createReducer<IInitState, ActionType<typeof actions>>(
  defaultState,
).handleAction(initAppSuccessAction, (state) => ({
  ...state,
  appInitialized: true,
}));
