import { IconNames } from 'components/atoms';
import React from 'react';

import { IEnabledButton } from 'types/common';

import {
  Wrapper,
  ControlsTopWrapper,
  ControlsBottomWrapper,
  ButtonControlStyled,
  ButtonStyled,
} from './styles';

export enum ControlsButtonColors {
  GREEN = 'GREEN',
  RED = 'RED',
  BROWN = 'BROWN',
  BLUE = 'BLUE',
}

export interface IProps {
  openRoundLoading: boolean;
  startDrawLoading: boolean;
  confirmResultLoading: boolean;
  onOpenRoundClick: () => void;
  onStartDrawClick: () => void;
  onConfirmResultClick: () => void;
  onChangeResultClick: () => void;
  onCancelRoundClick: () => void;
  enabledButtons: IEnabledButton;
  className?: string;
}

export const Controls = ({
  openRoundLoading,
  startDrawLoading,
  confirmResultLoading,
  enabledButtons: {
    isButtonConfirmResultEnabled,
    isButtonChangeResultEnabled,
    isButtonCancelRoundEnabled,
    isButtonOpenRoundEnabled,
    isButtonStartDrawEnabled,
  },
  onOpenRoundClick,
  onStartDrawClick,
  onConfirmResultClick,
  onChangeResultClick,
  onCancelRoundClick,
  className,
}: IProps) => (
  <Wrapper className={className}>
    <ControlsTopWrapper>
      <ButtonStyled
        onClick={onOpenRoundClick}
        loading={openRoundLoading}
        disabled={!isButtonOpenRoundEnabled}
        color={ControlsButtonColors.GREEN}>
        Open round
      </ButtonStyled>
      <ButtonStyled
        onClick={onStartDrawClick}
        loading={startDrawLoading}
        disabled={!isButtonStartDrawEnabled}
        color={ControlsButtonColors.GREEN}>
        Start draw
      </ButtonStyled>
    </ControlsTopWrapper>
    <ControlsBottomWrapper>
      <ButtonControlStyled
        iconName={IconNames.CheckCircle}
        onClick={onConfirmResultClick}
        loading={confirmResultLoading}
        disabled={!isButtonConfirmResultEnabled}
        color={ControlsButtonColors.GREEN}>
        Confirm result
      </ButtonControlStyled>
      <ButtonControlStyled
        iconName={IconNames.SmartRecord}
        onClick={onChangeResultClick}
        disabled={!isButtonChangeResultEnabled}
        color={ControlsButtonColors.BROWN}>
        Change result
      </ButtonControlStyled>
      <ButtonControlStyled
        iconName={IconNames.Replay}
        onClick={onCancelRoundClick}
        disabled={!isButtonCancelRoundEnabled}
        color={ControlsButtonColors.BLUE}>
        Cancel round
      </ButtonControlStyled>
    </ControlsBottomWrapper>
  </Wrapper>
);
