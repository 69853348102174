import { takeEvery, call, cancelled, fork, take, cancel } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';

import { webSocketService } from 'services/WebSocketService';
import {
  Notifications,
  ClientSideNotifications,
  ErrorNotifications,
  DealerChangedResponse,
  RoundStartedResponse,
  DealerJoinedResponse,
  TableOpenedResponse,
  DrawStartedResponse,
  BettingTimeStartedResponse,
  BettingTimeFinishedResponse,
  DrawFinishedResponse,
  ResultConfirmedResponse,
  RoundCanceledResponse,
  RoundFinishedResponse,
  TableClosedResponse,
  ResultChangedResponse,
  GameMachineConnectionResponse,
  TableCloseVerifyResponse,
  SocketPongResponse,
  ErrorNotificationResponse,
  ChatTokenProvidedResponse,
} from 'types';

import { connectToWebSocketAction } from '../reducers';
import { ACTIONS } from '../constants';
import {
  dealerJoined,
  tableOpened,
  roundStarted,
  bettingTimeStarted,
  bettingTimeFinished,
  drawStarted,
  roundCanceled,
  dealerChanged,
  drawFinished,
  resultConfirmed,
  resultChanged,
  roundFinished,
  tableClosed,
  gameMachineConnection,
  tableCloseNotification,
  onSocketConnectedSaga,
  onSocketDisconnectedSaga,
  onSocketErrorSaga,
  socketPongSaga,
  onErrorNotificationSaga,
  chatTokenProvided,
} from './notificationHandlers';
import {
  openTableSaga,
  startRoundSaga,
  startDrawSaga,
  cancelRoundSaga,
  changeDealerSaga,
  confirmResultSaga,
  closeTableSaga,
  verifyCloseTableSaga,
  verifyChangeResultSaga,
  changeResultSaga,
} from './notificationRequest';
import {
  createWebSocketChannel,
  simulateWebSocketChannel,
  errorWebSocketChannel,
} from './channels';
import {
  onJackpotStateUpdatedData,
  onJackpotWonData,
  onJackpotStageStartedData,
  OnJackpotCreatedData,
} from './types';
import { onJackpotStageStartedSaga } from './onJackpotStageStartedSaga';
import { onJackpotFinishedSaga } from './onJackpotFinishedSaga';
import { onJackpotStateUpdatedSaga } from './onJackpotStateUpdatedSaga';
import { onJackpotCreatedSaga } from './onJackpotCreatedSaga';
import { onJackpotUpdatedSaga } from './onJackpotUpdatedSaga';
import { connectToChatWebsocket } from './chatWebsoketSaga';

export function* listenJackpotStageStarted() {
  // @ts-ignore
  // eslint-disable-next-line no-undef
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_STAGE_STARTED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotStageStartedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotStageStartedWrapper(notification: onJackpotStageStartedData) {
  yield call(onJackpotStageStartedSaga, notification);
}

// -------listenJackpotStateUpdated

export function* listenJackpotStateUpdated() {
  // @ts-ignore
  // eslint-disable-next-line no-undef
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_STATE_UPDATED,
  ]);

  console.log('listenPointAwarded');

  try {
    yield takeEvery(notificationChannel, onJackpotStateUpdatedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotStateUpdatedWrapper(notification: onJackpotStateUpdatedData) {
  yield call(onJackpotStateUpdatedSaga, notification);
}

// -------listenJackpotCreated

export function* listenJackpotCreated() {
  // @ts-ignore
  // eslint-disable-next-line no-undef
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_CREATED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotCreatedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotCreatedWrapper(notification: OnJackpotCreatedData) {
  yield call(onJackpotCreatedSaga, notification);
}

// -------listenJackpotCreated

export function* listenJackpotUpdated() {
  // @ts-ignore
  // eslint-disable-next-line no-undef
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_UPDATED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotUpdatedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotUpdatedWrapper(notification: OnJackpotCreatedData) {
  yield call(onJackpotUpdatedSaga, notification);
}

// -------listenJackpotFinished

export function* listenJackpotFinished() {
  // @ts-ignore
  // eslint-disable-next-line no-undef
  const notificationChannel: EventChannel<any> = yield call(createWebSocketChannel, [
    Notifications.JACKPOT_FINISHED,
  ]);

  try {
    yield takeEvery(notificationChannel, onJackpotFinishedWrapper);
  } finally {
    const canceled: boolean = yield cancelled();
    if (canceled) {
      notificationChannel.close();
    }
  }
}

function* onJackpotFinishedWrapper(notification: onJackpotWonData) {
  yield call(onJackpotFinishedSaga, notification);
}

function* listenWebSocketMessages(): any {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.DEALER_CONNECTED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: DealerJoinedResponse) {
      yield call(dealerJoined, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenTableOpenedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.TABLE_OPENED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: TableOpenedResponse) {
      yield call(tableOpened, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenTableClosedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.TABLE_CLOSED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: TableClosedResponse) {
      yield call(tableClosed, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenDealerChangedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.DEALER_CHANGED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: DealerChangedResponse) {
      yield call(dealerChanged, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenRoundStartedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.ROUND_STARTED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: RoundStartedResponse) {
      yield call(roundStarted, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenRoundCanceledMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.ROUND_CANCELED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: RoundCanceledResponse) {
      yield call(roundCanceled, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenBettingTimeStartedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [
    Notifications.BETTING_TIME_STARTED,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: BettingTimeStartedResponse) {
      yield call(bettingTimeStarted, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenBettingTimeFinishedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [
    Notifications.BETTING_TIME_FINISHED,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: BettingTimeFinishedResponse) {
      yield call(bettingTimeFinished, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenDrawStartedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.DRAW_STARTED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: DrawStartedResponse) {
      yield call(drawStarted, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenDrawFinishedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.DRAW_FINISHED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: DrawFinishedResponse) {
      yield call(drawFinished, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenResultConfirmedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.RESULT_CONFIRMED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: ResultConfirmedResponse) {
      yield call(resultConfirmed, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenResultChangedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.RESULT_CHANGED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: ResultChangedResponse) {
      yield call(resultChanged, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenRoundFinishedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.ROUND_FINISHED]);

  try {
    yield takeEvery(notificationChannel, function* (notification: RoundFinishedResponse) {
      yield call(roundFinished, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenGameMachineConnectionMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [
    Notifications.GAME_MACHINE_CONNECTION,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: GameMachineConnectionResponse) {
      yield call(gameMachineConnection, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenTableCloseNotificationMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.NOTIFICATION]);

  try {
    yield takeEvery(notificationChannel, function* (notification: TableCloseVerifyResponse) {
      yield call(tableCloseNotification, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

export function* listenChatTokenProvided() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [
    Notifications.CHAT_TOKEN_PROVIDED,
  ]);
  try {
    yield takeEvery(notificationChannel, function* (notification: ChatTokenProvidedResponse) {
      yield call(chatTokenProvided, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenSocketPongMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.PONG]);

  try {
    yield takeEvery(notificationChannel, function* (notification: SocketPongResponse) {
      yield call(socketPongSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenSocketConnectedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(simulateWebSocketChannel, [
    ClientSideNotifications.SOCKET_CONNECTED,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: string) {
      yield call(onSocketConnectedSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenSocketDisconnectedMessage() {
  // @ts-ignore
  const notificationChannel = yield call(simulateWebSocketChannel, [
    ClientSideNotifications.SOCKET_DISCONNECTED,
  ]);

  try {
    yield takeEvery(notificationChannel, function* (notification: string) {
      yield call(onSocketDisconnectedSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenErrorNotificationMessage() {
  // @ts-ignore
  const notificationChannel = yield call(createWebSocketChannel, [Notifications.ERROR]);

  try {
    yield takeEvery(notificationChannel, function* (notification: ErrorNotificationResponse) {
      yield call(onErrorNotificationSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

function* listenSocketErrorMessage() {
  // @ts-ignore
  const notificationChannel = yield call(errorWebSocketChannel, [ErrorNotifications.CONNECT_ERROR]);

  try {
    yield takeEvery(notificationChannel, function* (notification: string) {
      yield call(onSocketErrorSaga, notification);
    });
  } finally {
    // @ts-ignore
    if (yield cancelled()) {
      notificationChannel.close();
    }
  }
}

export function* connectToWebSocket({
  payload: { tableId, token },
}: ActionType<typeof connectToWebSocketAction>) {
  // @ts-ignore
  if (!(yield call(() => webSocketService.isConnected()))) {
    yield call(() => webSocketService.connect(tableId, token));

    // @ts-ignore
    const taskDealerConnected = yield fork(listenWebSocketMessages);
    // @ts-ignore
    const taskTableOpened = yield fork(listenTableOpenedMessage);
    // @ts-ignore
    const taskTableClosed = yield fork(listenTableClosedMessage);
    // @ts-ignore
    const taskDealerChanged = yield fork(listenDealerChangedMessage);
    // @ts-ignore
    const taskRoundStarted = yield fork(listenRoundStartedMessage);
    // @ts-ignore
    const taskRoundCanceled = yield fork(listenRoundCanceledMessage);
    // @ts-ignore
    const taskBettingTimeStarted = yield fork(listenBettingTimeStartedMessage);
    // @ts-ignore
    const taskBettingTimeFinished = yield fork(listenBettingTimeFinishedMessage);
    // @ts-ignore
    const taskDrawStarted = yield fork(listenDrawStartedMessage);
    // @ts-ignore
    const taskDrawFinished = yield fork(listenDrawFinishedMessage);
    // @ts-ignore
    const taskResultConfirmed = yield fork(listenResultConfirmedMessage);
    // @ts-ignore
    const taskResultChanged = yield fork(listenResultChangedMessage);
    // @ts-ignore
    const taskRoundFinished = yield fork(listenRoundFinishedMessage);
    // @ts-ignore
    const taskGameMachineConnection = yield fork(listenGameMachineConnectionMessage);
    // @ts-ignore
    const taskTableCloseNotification = yield fork(listenTableCloseNotificationMessage);
    // @ts-ignore
    const taskSocketConnected = yield fork(listenSocketConnectedMessage);
    // @ts-ignore
    const taskSocketDisconnected = yield fork(listenSocketDisconnectedMessage);
    // @ts-ignore
    const taskSocketError = yield fork(listenSocketErrorMessage);
    // @ts-ignore
    const taskErrorNotification = yield fork(listenErrorNotificationMessage);
    // @ts-ignore
    const taskSocketPong = yield fork(listenSocketPongMessage);
    // @ts-ignore
    const taskJackpotStageStarted = yield fork(listenJackpotStageStarted);
    // @ts-ignore
    const taskJackpotUpdated = yield fork(listenJackpotUpdated);
    // @ts-ignore
    const taskJackpotStateUpdated = yield fork(listenJackpotStateUpdated);
    // @ts-ignore
    const taskJackpotFinished = yield fork(listenJackpotFinished);
    // @ts-ignore
    const taskJackpotCreated = yield fork(listenJackpotCreated);
    // @ts-ignore
    const taskChatTokenProvided = yield fork(listenChatTokenProvided);

    yield take(ACTIONS.CLEAR_SAGA_CHANNELS);
    yield call(() => webSocketService.disconnect());
    yield cancel(taskDealerConnected);
    yield cancel(taskTableOpened);
    yield cancel(taskTableClosed);
    yield cancel(taskDealerChanged);
    yield cancel(taskRoundStarted);
    yield cancel(taskRoundCanceled);
    yield cancel(taskBettingTimeStarted);
    yield cancel(taskBettingTimeFinished);
    yield cancel(taskDrawStarted);
    yield cancel(taskDrawFinished);
    yield cancel(taskResultConfirmed);
    yield cancel(taskResultChanged);
    yield cancel(taskRoundFinished);
    yield cancel(taskGameMachineConnection);
    yield cancel(taskTableCloseNotification);
    yield cancel(taskSocketConnected);
    yield cancel(taskSocketDisconnected);
    yield cancel(taskSocketError);
    yield cancel(taskSocketPong);
    yield cancel(taskSocketError);
    yield cancel(taskErrorNotification);
    yield cancel(taskSocketPong);
    yield cancel(taskJackpotStateUpdated);
    yield cancel(taskJackpotFinished);
    yield cancel(taskJackpotCreated);
    yield cancel(taskJackpotStageStarted);
    yield cancel(taskJackpotUpdated);
    yield cancel(taskChatTokenProvided);
  }
}

export function* startListeningWebSocketMessagesSaga() {
  yield takeEvery(ACTIONS.CONNECT_TO_WEBSOCKET, connectToWebSocket);
  yield takeEvery(ACTIONS.CONNECT_TO_CHAT_WEBSOCKET, connectToChatWebsocket);
  yield takeEvery(ACTIONS.OPEN_TABLE, openTableSaga);
  yield takeEvery(ACTIONS.CHANGE_DEALER, changeDealerSaga);
  yield takeEvery(ACTIONS.START_ROUND, startRoundSaga);
  yield takeEvery(ACTIONS.CANCEL_ROUND, cancelRoundSaga);
  yield takeEvery(ACTIONS.START_DRAW, startDrawSaga);
  yield takeEvery(ACTIONS.CONFIRM_RESULT, confirmResultSaga);
  yield takeEvery(ACTIONS.CLOSE_TABLE_VERIFY, verifyCloseTableSaga);
  yield takeEvery(ACTIONS.CLOSE_TABLE, closeTableSaga);
  yield takeEvery(ACTIONS.CHANGE_RESULT_VERIFY, verifyChangeResultSaga);
  yield takeEvery(ACTIONS.CHANGE_RESULT, changeResultSaga);
}
