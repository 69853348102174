import fontService, { FontWeight } from 'services/FontService';
import styled from 'styled-components';
import { LoadingIndicator } from 'atoms';

export const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  padding: 24px 16px 24px 24px;
  background: linear-gradient(180deg, rgba(18, 16, 17, 0.6) 0%, #121011 86.04%);
  width: 100%;
  height: 176px;
`;

export const ContentScrollable = styled.div<{ fontSize: number }>`
  ${({ fontSize }) => fontService.text({ size: fontSize, weight: FontWeight.medium })};
  overflow-y: auto;
  padding-right: 8px;
  width: 100%;
  height: 100%;

  &::-webkit-scrollbar {
    width: 2px;
    height: 0;

    ${({ theme: { button } }) => `
      background: ${button.cancel};
    `}
  }

  &::-webkit-scrollbar-track {
    border-radius: 2px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    border-radius: 2px;
    ${({ theme: { button } }) => `
      background-color: ${button.primaryBrightBorder};
    `}
  }
`;

export const Message = styled.div``;

export const MessageAuthor = styled.span<{ isSupport: boolean }>`
  ${({ isSupport, theme: { text } }) => `
    color: ${isSupport ? text.messageAuthorSupport : text.messageAuthorDefault};
  `}
`;

export const MessageText = styled.span`
  ${({ theme: { text } }) => `
    color: ${text.primary};
  `}
`;

export const FontSizeBtns = styled.div`
  position: absolute;
  top: -64px;
  right: 16px;
  background: linear-gradient(180deg, rgba(18, 16, 17, 0.6) 0%, #121011 86.04%);
  border-radius: 8px;
  ${({ theme: { button } }) => `
    border: 1px solid ${button.cancel}a3;
  
    &:after {
      position: absolute;
      content: '';
      width: 2px;
      height: 12px;
      top: calc(50% - 6px);
      background-color: ${button.cancel}a3;
      right: calc(50% - 1px);
    }
  `}
`;

export const AdjustButton = styled.button<{ isDisabled: boolean }>`
  background: transparent;
  border: none;
  outline: none;
  padding: 12px;
  width: 56px;
  height: 56px;

  ${({ isDisabled, theme: { text } }) => `
    cursor: ${isDisabled ? 'auto' : 'pointer'};

    &:hover {
      svg {
        path {
          fill: ${text.secondaryDefault};
        }
      }
    }

    ${
      isDisabled &&
      `
        svg {
          path {
            fill: ${text.primaryDisabled};
          }
        }
  
        &:hover {
          svg {
            path {
              fill: ${text.primaryDisabled};
            }
          }
        }
      `
    }
  `}
`;

export const StyledLoadingIndicator = styled(LoadingIndicator)`
  width: 40px;
  height: 40px;
  position: absolute;
  left: 46%;
  top: 35%;
`;

export const ConnectionError = styled.div`
  width: 100%;
  height: 100%;
  ${fontService.text({ size: 16, lineHeight: 150, weight: FontWeight.medium })};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  color: #686868;
`;
