import React, { memo, useCallback } from 'react';

import { useNavigate } from 'react-router-dom';

import { TableCardComponent, IProps } from './TableCard';

interface ITableCard extends Pick<IProps, 'tableData'> {}

export const TableCard = memo(({ tableData }: ITableCard) => {
  const navigate = useNavigate();

  const onOpenTable = useCallback(() => {
    navigate(`/open_table/${tableData.table_id}`, { replace: true });
  }, [navigate, tableData]);

  return <TableCardComponent tableData={tableData} onOpenTable={onOpenTable} />;
});
