import { all } from 'redux-saga/effects';

import {
  initSaga,
  authorizationSaga,
  tableSaga,
  startListeningWebSocketMessagesSaga,
} from 'data/sagas';
import { watchDatesControlSaga } from './watchDatesControlSaga';
import { watchDecrementTimeLeftSaga } from './watchTimeLeftSaga';

export function* rootSaga() {
  try {
    yield all([
      initSaga(),
      authorizationSaga(),
      tableSaga(),
      startListeningWebSocketMessagesSaga(),
      watchDatesControlSaga(),
      watchDecrementTimeLeftSaga(),
    ]);
  } catch (e) {
    global.console.log(e);
  }
}
