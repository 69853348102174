import React, { useMemo } from 'react';
import { Ball } from 'components/atoms';
import { SelectOptionType, ResultBall } from 'types';
import { OptionLabel, OptionValue, OptionWrapper, Wrapper } from './styles';

export interface IProps {
  options: SelectOptionType<ResultBall>[];
  value?: ResultBall;
  onChange: (value: ResultBall) => void;
}

export const SelectResult = ({ options, value, onChange }: IProps) => {
  const items = useMemo(
    () =>
      options.map((option) => (
        <OptionWrapper
          key={option.value}
          isActive={option.value === value}
          onClick={() => onChange(option.value)}>
          <OptionValue>
            <Ball value={option.value} />
          </OptionValue>
          <OptionLabel>{option.label}</OptionLabel>
        </OptionWrapper>
      )),
    [options, value, onChange],
  );

  return <Wrapper>{items}</Wrapper>;
};
