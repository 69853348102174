import React from 'react';
import { TableStates } from 'types';
import {
  Header,
  StatusCaption,
  StatusSeparator,
  StatusValue,
  Time,
  TimeUnits,
  TimeValue,
  Wrapper,
} from './styles';

export interface IProps {
  status: TableStates;
  timeValue?: number;
  isFinished: boolean;
  statusText: string;
  className?: string;
}

export const StatusInfo = ({ timeValue, status, isFinished, statusText, className }: IProps) => (
  <Wrapper className={className}>
    <Header>
      <StatusCaption>Status</StatusCaption>
      <StatusValue>{statusText}</StatusValue>
    </Header>
    <StatusSeparator />
    <Time finished={isFinished} status={status}>
      <TimeValue>{timeValue}</TimeValue>
      <TimeUnits>sec</TimeUnits>
    </Time>
  </Wrapper>
);
