import { BaseService } from './BaseService';

class TableAPIService extends BaseService {
  public async getTables() {
    const route = `tables/`;

    return this.get(route);
  }

  public async getTable(id: string) {
    const route = `tables/${id}/`;

    return this.get(route);
  }
}

export const TableService = new TableAPIService();
