import styled from 'styled-components';
import fontService, { FontWeight, TextType } from 'services/FontService';
import { ButtonRound } from 'components/atoms';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StageText = styled.p`
  color: var(--off-white-fff-7-eb, #fff7eb);
  text-align: center;
  font-family: 'HalvarBreit';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px; /* 88.889% */
  text-transform: uppercase;

  svg {
    margin: 0 4px 0 10px;
  }
`;

export const StageCountdown = styled.div`
  border-radius: 8px;
  background: #7107aa;
  display: inline-flex;
  padding: 8px 20px;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  min-width: 172px;
  text-align: center;
`;

export const TableDescription = styled.div`
  display: flex;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;

  &:not(:first-of-type) {
    &:before {
      content: '';
      display: block;
      height: 8px;
      width: 1px;
      margin: 40px;

      ${({ theme: { text } }) => `
        background-color: ${text.primaryDisabled};
      `}
    }
  }
`;

export const ItemInner = styled.div``;

export const ItemLabel = styled.p`
  ${fontService.text({ type: TextType.captionSmall })};
  ${({ theme: { text } }) => `
    color: ${text.secondaryDisabled};
  `}
`;

export const ItemValue = styled.p`
  margin-top: 4px;
  ${fontService.text({ type: TextType.normal, lineHeight: 112, weight: FontWeight.bold })};
  ${({ theme: { text } }) => `
    color: ${text.secondaryDefault};
  `}
`;

export const ControlsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Buttons = styled.div`
  display: flex;
  margin-left: 32px;
`;

const RoundButtonStyled = styled(ButtonRound)`
  ${({ theme: { button } }) => `
    &:disabled {
      background-color: ${button.cancel};
      border-color: ${button.cancelBorder};

      & > * {
        opacity: 0.24;
      }
    }
 `};
`;

export const UserButton = styled(RoundButtonStyled)`
  margin-left: 24px;
  ${({ theme: { button } }) => `
    border-color: ${button.primaryBorder};
    background-color: ${button.primary};

    &:hover:not([disabled]) {
      background-color: ${button.primaryHover};
    }
  `}
`;

export const PowerButton = styled(RoundButtonStyled)`
  ${({ theme: { button } }) => `
    border-color: ${button.errorBorder};
    background-color: ${button.error};

    &:hover:not([disabled]) {
      background-color: ${button.errorHover};
    }
  `}
`;
