import { useCallback, useState } from 'react';

type ModalHookType = {
  isShown: boolean;
  toggle: () => void;
  open: () => void;
  close: () => void;
};

export const useModal = (): ModalHookType => {
  const [isShown, setShown] = useState<boolean>(false);

  const toggle = useCallback(() => {
    setShown((prevState) => !prevState);
  }, [setShown]);

  const open = useCallback(() => {
    setShown(true);
  }, []);

  const close = useCallback(() => {
    setShown(false);
  }, []);

  return {
    isShown,
    toggle,
    open,
    close,
  };
};
