import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import { NotificationContainer } from 'react-notifications';

import { Router } from 'components/pages';

import { GlobalStyles, darkThemeColors } from 'constants/theme';
import { initAppRequstAction } from 'data/reducers';
import { useDispatch } from 'react-redux';

export const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initAppRequstAction());
  }, [dispatch]);

  return (
    <ThemeProvider theme={darkThemeColors}>
      <GlobalStyles />
      <Router />
      <NotificationContainer />
    </ThemeProvider>
  );
};
