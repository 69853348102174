import { call, put } from 'redux-saga/effects';

import { Notifications } from 'types';

import { webSocketService } from 'services/WebSocketService';
import { changeDealerAction, changeDealerFailureAction } from 'data/reducers';
import { ActionType } from 'typesafe-actions';

import { responseType } from 'constants/common';

export function* changeDealerSaga({
  payload: { dealerId },
}: ActionType<typeof changeDealerAction>) {
  const { type, data } = yield call(() =>
    webSocketService.sendMessage({
      type: Notifications.CHANGE_DEALER,
      data: { dealerId },
    }),
  );

  if (type === responseType.Error) {
    yield put(changeDealerFailureAction(data));
  }
}
