import { call, put } from 'redux-saga/effects';

import { Notifications } from 'types';

import { webSocketService } from 'services/WebSocketService';
import {
  tableCloseVerifyAction,
  tableCloseVerifySuccessAction,
  tableCloseVerifyFailureAction,
} from 'data/reducers';
import { ActionType } from 'typesafe-actions';

import { responseType } from 'constants/common';

export function* verifyCloseTableSaga({
  payload: { shiftManagerId },
}: ActionType<typeof tableCloseVerifyAction>) {
  const { type, data } = yield call(() =>
    webSocketService.sendMessage({
      type: Notifications.VERIFY_SHIFT_MANAGER,
      data: { shiftManagerId },
    }),
  );
  if (type === responseType.Error) {
    yield put(tableCloseVerifyFailureAction(data));
  }

  if (type === responseType.Ok) {
    yield put(tableCloseVerifySuccessAction());
  }
}
