import { EmptyActionCreator, PayloadAction } from 'typesafe-actions';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';

import { IState } from 'core/types';
import { clearErrorByActionType } from 'data/reducers';
import { ICustomError } from 'types';

type ActionCreator = (payload: any) => PayloadAction<string, any>;

export function useRequestError(action: EmptyActionCreator<string> | ActionCreator): {
  error: Error | ICustomError | AxiosError<{ status: number; statusText: string }> | null;
  clearError: () => void;
} {
  const key = String(action).replace('_REQUEST', '');
  const dispatch = useDispatch();

  const clearError = useCallback(() => {
    dispatch(clearErrorByActionType(key));
  }, [dispatch, key]);

  useEffect(() => clearError, [clearError]);

  const error = useSelector((state: IState) => state.error[key] || null);

  const result = useMemo(
    () => ({
      error,
      clearError,
    }),
    [clearError, error],
  );

  return result;
}
