import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { getAppInitialized } from 'core/selectors';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { LogIn } from '../Authorization';
import { Table } from '../Tables';
import { OpenTable } from '../OpenTablePage';
import { PrivateRoutes } from './PrivateRoutes';
import { TablePage } from '../TablePage';
import { NotAvailablePage } from '../NotAvailablePage';

export const Router = memo(() => {
  const isAppInitialized = useSelector(getAppInitialized);

  return isAppInitialized ? (
    <BrowserRouter>
      <Routes>
        <Route element={<PrivateRoutes />}>
          <Route path="/tables" element={<Table />} />
          <Route path="/" element={<Navigate to="/tables" />} />
          <Route path="/open_table/:id" element={<OpenTable />} />
          <Route path="/table/:id" element={<TablePage />} />
        </Route>
        <Route path="/login" element={<LogIn />} />
        <Route path="/not_found" element={<NotAvailablePage />} />
        <Route path="*" element={<NotAvailablePage />} />
      </Routes>
    </BrowserRouter>
  ) : (
    <div />
  );
});
