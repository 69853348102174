import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  z-index: 9;
`;

export const Backdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(18, 16, 17, 0.6) 0%, #121011 86.04%);
`;

export const Container = styled.div`
  position: relative;
  width: min-content;
  border-radius: 4px 4px 12px 12px;
  box-shadow: 0px 16px 40px rgba(18, 16, 17, 0.25);
  overflow: hidden;

  ${({ theme: { background } }) => css`
    background-color: ${background.primaryDefault};
  `};
`;

export const Head = styled.div`
  display: flex;
  align-items: center;
  padding: 16px 32px;
  ${fontService.text({ size: 18, lineHeight: 222, weight: FontWeight.bold })};
  text-transform: uppercase;
  box-shadow: inset 0px -1px 0px #bb9370;

  ${({ theme: { text, background } }) => css`
    background-color: ${background.primaryLight};
    color: ${text.primaryDefault};
  `};
`;

export const CloseButton = styled.div`
  position: absolute;
  right: 24px;
  height: 24px;
  width: 24px;
  cursor: pointer;
`;

export const Content = styled.div`
  padding: 32px 32px 48px;
`;
