import { put, takeEvery, all } from 'redux-saga/effects';

import { initAppFailureAction, refreshTokenRequstAction } from 'data/reducers';

import { ACTIONS } from '../constants';

function* initAppSaga() {
  try {
    yield put(refreshTokenRequstAction());
  } catch (err) {
    yield put(initAppFailureAction());
  }
}

export function* initSaga() {
  yield all([takeEvery(ACTIONS.INIT_APP_REQUEST, initAppSaga)]);
}
