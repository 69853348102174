import React, { useMemo } from 'react';
import { KeyButton, Table, TableCell, TableRow, Wrapper } from './styles';

const KEYPAD_ROW_VALUES = {
  first: ['1', '2', '3', '4', '5'],
  second: ['6', '7', '8', '9', '0'],
};

export interface IProps {
  disabled?: boolean;
  onClick: (value: string) => void;
}

export const Keypad = ({ disabled = false, onClick }: IProps) => {
  const rows = useMemo(() => {
    const rowItems = Object.values(KEYPAD_ROW_VALUES).map((row, index) => {
      const cells = row.map((cell) => (
        <TableCell key={cell}>
          <KeyButton type="button" onClick={disabled ? undefined : () => onClick(cell)}>
            {cell}
          </KeyButton>
        </TableCell>
      ));

      return <TableRow key={index}>{cells}</TableRow>;
    });

    return rowItems;
  }, [disabled, onClick]);

  return (
    <Wrapper>
      <Table disabled={disabled}>
        <tbody>{rows}</tbody>
      </Table>
    </Wrapper>
  );
};
