import styled, { css } from 'styled-components';
import { Icon } from '../Icon';

export const ButtonStyled = styled.button<{ disabled: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  padding: 0;
  border: none;
  border-radius: 50%;
  cursor: pointer;

  ${({ disabled }) => {
    if (disabled) {
      return css`
        cursor: not-allowed;
      `;
    }
  }};
`;

export const IconStyled = styled(Icon)`
  height: 16px;
  width: 16px;
`;
