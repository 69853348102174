import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  roundFinishedSuccessAction,
  changeGameStatusAction,
  changeGameStateAction,
} from 'data/reducers';
import { RoundFinishedResponse, Notifications, TableStates } from 'types';
import { gameStatus } from 'constants/common';
import { setJackpotWon, setStageJackpot } from 'data/reducers/AuthReducer';

export function* roundFinished(data: RoundFinishedResponse) {
  try {
    if (data.type === Notifications.ROUND_FINISHED) {
      yield put(setJackpotWon({ amount: null, players: null, currency: null }));
      yield put(setStageJackpot(null));

      yield put(changeGameStateAction({ tableState: TableStates.RoundFinished }));
      yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
      yield put(roundFinishedSuccessAction());
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
