import { ballStyles } from 'constants/balls';
import styled, { css } from 'styled-components';
import { ResultBall } from 'types';
import { BallSize } from './Ball';

export const BallColored = styled.div<{ value: ResultBall; size?: BallSize }>`
  border-radius: 50%;
  ${({ value }) => ballStyles.default[value]};
  ${({ size }) => {
    switch (size) {
      case BallSize.Small:
        return css`
          height: 24px;
          width: 24px;
        `;
      default:
        return css`
          height: 100%;
          width: 100%;
        `;
    }
  }}
`;
