import React from 'react';
import { ResultBall } from 'types';
import { BallColored } from './styles';

export enum BallSize {
  Small = 'Small',
}

export interface IProps {
  value: ResultBall;
  size?: BallSize;
}

export const Ball = ({ value, size }: IProps) => <BallColored value={value} size={size} />;
