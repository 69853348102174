import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { Notifications } from 'types';
import { setJackpotWon, setStageJackpot } from 'data/reducers/AuthReducer';
import { onJackpotWonData } from './types';

// eslint-disable-next-line require-yield
export function* onJackpotFinishedSaga({ type: notificationType, data: result }: onJackpotWonData) {
  try {
    const { players, amount, currency } = result;
    if (notificationType === Notifications.JACKPOT_FINISHED) {
      yield put(setJackpotWon({ amount, players, currency }));
      yield put(setStageJackpot(null));
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
