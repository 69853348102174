import { ActionType, createAction, createReducer } from 'typesafe-actions';
import { TableCommonType } from 'types';

import { ACTIONS } from '../constants';

export interface ITableState {
  tablesData: TableCommonType[];
  selectedTableData: TableCommonType | null;
}

export const tableState: ITableState = {
  tablesData: [],
  selectedTableData: null,
};

export const getTablesRequest = createAction(ACTIONS.GET_TABLES_REQUEST)();
export const getTablesSuccess = createAction(ACTIONS.GET_TABLES_SUCCESS)();
export const getTablesFailure = createAction(ACTIONS.GET_TABLES_FAILURE)<Error>();

export const getTableRequest = createAction(ACTIONS.GET_TABLE_REQUEST)<{ id: string }>();
export const getTableSuccess = createAction(ACTIONS.GET_TABLE_SUCCESS)();
export const getTableFailure = createAction(ACTIONS.GET_TABLE_FAILURE)<Error>();

export const saveTablesToStore = createAction(ACTIONS.SAVE_TABLES_TO_STORE)<{
  tablesData: TableCommonType[];
}>();

export const saveSelectedTableToStore = createAction(ACTIONS.SAVE_SELECTED_TABLE_TO_STORE)<{
  selectedTableData: TableCommonType;
}>();
export const clearSelectedTableToStore = createAction(ACTIONS.CLEAR_SELECTED_TABLE_TO_STORE)();

const actions = {
  saveTablesToStore,
  saveSelectedTableToStore,
  clearSelectedTableToStore,
};

export const tablesReducer = createReducer<ITableState, ActionType<typeof actions>>(tableState)
  .handleAction(saveTablesToStore, (state, { payload: { tablesData } }) => ({
    ...state,
    tablesData,
  }))
  .handleAction(saveSelectedTableToStore, (state, { payload: { selectedTableData } }) => ({
    ...state,
    selectedTableData,
  }))
  .handleAction(clearSelectedTableToStore, (state) => ({
    ...state,
    selectedTableData: null,
  }));
