import { delay, put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { Notifications } from 'types';
import { setJackpot } from 'data/reducers/AuthReducer';
import { PositionJackpot } from 'types/authorization';
import { ACTIONS } from 'data/constants';
import { onJackpotStateUpdatedData } from './types';

// eslint-disable-next-line require-yield
export function* onJackpotStateUpdatedSaga({
  type: notificationType,
  data: result,
}: onJackpotStateUpdatedData) {
  if (notificationType === Notifications.JACKPOT_STATE_UPDATED) {
    try {
      const { jackpots } = result;

      const currentJackpot = jackpots?.find(
        (jackpot) => jackpot.position === PositionJackpot.CURRENT,
      );

      if (!currentJackpot) {
        yield put(setJackpot(null));
      }

      if (currentJackpot) {
        yield put(setJackpot(currentJackpot));
        yield delay(3000);
        yield put({ type: ACTIONS.WATCH_DATE_CONTROL_INTERVAL });
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }
}
