import { IconNames } from 'components/atoms';
import React, { useMemo } from 'react';
import { ResultBall } from 'types';
import { ResultLabel, ResultValue, ResultValueBG, Wrapper } from './styles';

export interface IProps {
  value?: string;
}

export const DrawResult = ({ value }: IProps) => {
  const ballIconName: IconNames = useMemo(() => {
    if (value === ResultBall.BLUE) {
      return IconNames.BallBlue;
    }

    if (value === ResultBall.RED) {
      return IconNames.BallRed;
    }

    if (value === ResultBall.GOLD) {
      return IconNames.BallGold;
    }

    return IconNames.BallUndefined;
  }, [value]);

  return (
    <Wrapper value={value}>
      {value !== undefined ? <ResultValueBG name={IconNames.BallBackground} /> : null}
      <ResultValue name={ballIconName} />
      <ResultLabel>{value === ResultBall.UNDEFINED ? 'Not Determined' : value}</ResultLabel>
    </Wrapper>
  );
};
