import React, { useMemo, useRef, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { ChatMessageType } from 'types';
import { Icon, IconNames } from 'atoms';

import {
  AdjustButton,
  ContentScrollable,
  FontSizeBtns,
  Message,
  MessageAuthor,
  MessageText,
  Wrapper,
  StyledLoadingIndicator,
  ConnectionError,
} from './styles';

export interface IProps {
  messages: ChatMessageType[];
  isLoading: boolean;
  connectionError: boolean;
}

const FONT_SIZES = {
  0: 18,
  1: 20,
  2: 24,
  3: 32,
  4: 36,
  5: 40,
  6: 48,
  7: 64,
};

export const Chat = ({ messages, isLoading, connectionError }: IProps) => {
  const [fontIndex, setFontIndex] = useState(0);
  const [isScrollBottom, setIsScrollBottom] = useState(true);

  const scrollContainerRef = useRef<HTMLDivElement>();
  const scrollBottomRef = useRef<HTMLDivElement>();
  const oldScrollY = useRef(0);

  const controlDirection = () => {
    if (scrollContainerRef.current) {
      const container = scrollContainerRef.current;
      if (!(container.scrollTop > oldScrollY.current)) {
        setIsScrollBottom(false);
      }

      if (container.scrollHeight - container.scrollTop - container.clientHeight < 1) {
        setIsScrollBottom(true);
      }

      oldScrollY.current = container.scrollTop;
    }
  };

  useEffect(() => {
    if (scrollBottomRef.current && isScrollBottom) {
      scrollBottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [scrollBottomRef, isScrollBottom, messages]);

  const onDecreaseClick = () => {
    if (messages.length > 0 && fontIndex > 0) {
      setFontIndex((prevState) => prevState - 1);
    }
  };

  const onIncreaseClick = () => {
    if (messages.length > 0 && fontIndex < 7) {
      setFontIndex((prevState) => prevState + 1);
    }
  };

  const messageItems = useMemo(
    () =>
      connectionError ? (
        <ConnectionError>
          Connection to the chat has been lost. <br />
          Please wait while the connection is being restored.
        </ConnectionError>
      ) : (
        messages.map((message) => (
          <Message key={`${message.playerName}_${uuidv4()}`}>
            <MessageAuthor isSupport={message.fromSupport}>{message.playerName}: </MessageAuthor>
            <MessageText>{message.message}</MessageText>
          </Message>
        ))
      ),
    [messages, connectionError],
  );

  return (
    <Wrapper>
      <FontSizeBtns>
        <AdjustButton
          onClick={onDecreaseClick}
          isDisabled={messages.length === 0 || fontIndex === 0}>
          <Icon name={IconNames.Remove} />
        </AdjustButton>
        <AdjustButton
          onClick={onIncreaseClick}
          isDisabled={messages.length === 0 || fontIndex === 7}>
          <Icon name={IconNames.Add} />
        </AdjustButton>
      </FontSizeBtns>
      <ContentScrollable
        ref={scrollContainerRef}
        onScroll={controlDirection}
        fontSize={FONT_SIZES[fontIndex]}>
        {isLoading ? <StyledLoadingIndicator /> : messageItems}
        <div ref={scrollBottomRef} />
      </ContentScrollable>
    </Wrapper>
  );
};
