import React from 'react';
import { TablesClosingStatus } from 'constants/tables';
import { SelectOptionType } from 'types';
import { Popup } from '../Popup/Popup';
import { Subtitle, SelectWrapper, ConfirmButton, ContentWrapper } from './styles';
import { SelectCustom } from '../SelectCustom/SelectCustom';

export interface IProps {
  options: SelectOptionType<TablesClosingStatus>[];
  value: TablesClosingStatus | undefined;
  loading: boolean;
  onChange: (value: TablesClosingStatus) => void;
  onConfirmClick: () => void;
  onClose: () => void;
}

export const PopupClosingTableComponent = ({
  options,
  value,
  loading,
  onChange,
  onConfirmClick,
  onClose,
}: IProps) => (
  <Popup title="Close table" onClose={onClose} loading={loading}>
    <ContentWrapper>
      <Subtitle>Table Status</Subtitle>
      <SelectWrapper>
        <SelectCustom options={options} value={value} onChange={onChange} />
      </SelectWrapper>
      <ConfirmButton disabled={value === undefined} loading={loading} onClick={onConfirmClick}>
        Confirm
      </ConfirmButton>
    </ContentWrapper>
  </Popup>
);
