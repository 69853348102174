import { call, put } from 'redux-saga/effects';
import { Notifications } from 'types';
import { webSocketService } from 'services/WebSocketService';
import { ActionType } from 'typesafe-actions';
import { cancelRoundAction, cancelRoundFailureAction } from 'data/reducers';

import { responseType } from 'constants/common';

export function* cancelRoundSaga({
  payload: { shiftManagerId },
}: ActionType<typeof cancelRoundAction>) {
  const { type, data } = yield call(() =>
    webSocketService.sendMessage({ type: Notifications.CANCEL_ROUND, data: { shiftManagerId } }),
  );

  if (type === responseType.Error) {
    yield put(cancelRoundFailureAction(data));
  }
}
