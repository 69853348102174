import { delay, put } from 'redux-saga/effects';

import { Notifications } from 'types';
import { setJackpot } from 'data/reducers/AuthReducer';
import { PositionJackpot } from 'types/authorization';
import { ACTIONS } from 'data/constants';
import { OnJackpotCreatedData } from './types';

export function* onJackpotCreatedSaga({
  type: notificationType,
  data: jackpot,
}: OnJackpotCreatedData) {
  if (notificationType === Notifications.JACKPOT_CREATED) {
    const isCurrent = jackpot.position === PositionJackpot.CURRENT;

    if (isCurrent) {
      yield put(setJackpot(jackpot));
      yield delay(3000);

      yield put({ type: ACTIONS.WATCH_DATE_CONTROL_INTERVAL });
    }
  }
}
