import { IconNames } from 'components/atoms';
import React, { useMemo } from 'react';
import { SelectOptionType } from 'types';
import { CheckIcon, SelectItem, Wrapper } from './styles';

export interface IProps<T> {
  value: T | undefined;
  options: SelectOptionType<T>[];
  onChange: (value: T) => void;
}

export const SelectCustom = <T extends unknown>({ value, options, onChange }: IProps<T>) => {
  const items = useMemo(
    () =>
      options.map((option) => {
        const isActive = option.value === value;

        return (
          <SelectItem
            key={option.value as string}
            isActive={isActive}
            disabled={Boolean(option.disabled)}
            onClick={() => (!option.disabled && !isActive ? onChange(option.value) : undefined)}>
            {option.label}
            {isActive ? <CheckIcon name={IconNames.Check} /> : null}
          </SelectItem>
        );
      }),
    [value, options, onChange],
  );

  return <Wrapper>{items}</Wrapper>;
};
