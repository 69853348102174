import { Icon } from 'components/atoms';
import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div``;

export const SelectItem = styled.div<{ isActive: boolean; disabled: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 24px;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  user-select: none;
  ${fontService.getFont({ size: 16, lineHeight: 150, weight: FontWeight.bold })};

  &:not(:first-of-type) {
    margin-top: 12px;
  }

  ${({ isActive, disabled, theme: { text, button } }) => {
    if (isActive) {
      return css`
        border-color: ${text.secondaryDefault};
        background: linear-gradient(180deg, rgba(52, 52, 52, 0) 0%, #343434 99.99%, #343434 100%);
        color: ${text.primaryDefault};
      `;
    }

    if (disabled) {
      return css`
        border-color: ${button.cancel};
        background-color: transparent;
        color: ${button.cancel};
        cursor: not-allowed;
      `;
    }

    return css`
      border-color: ${text.secondaryDisabledLight};
      color: ${text.secondaryDefault};
      cursor: pointer;
    `;
  }}
`;

export const CheckIcon = styled(Icon)`
  position: absolute;
  right: 24px;
`;
