import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  changeGameStatusAction,
  startDrawSuccessAction,
  changeGameStateAction,
  changeTimeLeftAction,
  drawTimeStartedAction,
} from 'data/reducers';
import { DrawStartedResponse, Notifications, TableStates } from 'types';
import { gameStatus } from 'constants/common';

export function* drawStarted({ type: notificationType, data: result }: DrawStartedResponse) {
  try {
    if (notificationType === Notifications.DRAW_STARTED) {
      const { drawStartedAt } = result;

      yield put(changeGameStateAction({ tableState: TableStates.DrawStarted }));
      yield put(changeGameStatusAction({ status: gameStatus.StartedDraw }));
      yield put(changeTimeLeftAction({ drawTimeLeft: 0 }));
      yield put(drawTimeStartedAction({ drawStartedAt }));
      yield put(startDrawSuccessAction());
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
