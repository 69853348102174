import { call, put } from 'redux-saga/effects';

import { Notifications } from 'types';

import { webSocketService } from 'services/WebSocketService';
import { tableCloseAction, tableCloseSuccessAction } from 'data/reducers';
import { ActionType } from 'typesafe-actions';

import { responseType } from 'constants/common';

export function* closeTableSaga({
  payload: { shiftManagerId, closingStatus },
}: ActionType<typeof tableCloseAction>) {
  const { type } = yield call(() =>
    webSocketService.sendMessage({
      type: Notifications.CLOSE_TABLE,
      data: { shiftManagerId, closing_status: closingStatus },
    }),
  );

  if (type === responseType.Ok) {
    yield put(tableCloseSuccessAction());
  }
}
