import * as React from 'react';
import { memo } from 'react';

import { Label, LabelVariants } from 'atoms';

export interface IConnectionStatusLabelComponent {
  variant?: LabelVariants;
  statusText: string;
}

export const ConnectionStatusLabelComponent = memo(
  ({ variant, statusText }: IConnectionStatusLabelComponent) => (
    <Label variant={variant}>{statusText}</Label>
  ),
);
