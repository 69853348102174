import { call, put } from 'redux-saga/effects';

import { Notifications } from 'types';

import { webSocketService } from 'services/WebSocketService';
import { changeResultAction, changeResultFailureAction } from 'data/reducers';
import { ActionType } from 'typesafe-actions';
import { responseType } from 'constants/common';

export function* changeResultSaga({
  payload: { shiftManagerId, winner },
}: ActionType<typeof changeResultAction>) {
  const { type, data } = yield call(() =>
    webSocketService.sendMessage({
      type: Notifications.CHANGE_RESULT,
      data: { shiftManagerId, winner },
    }),
  );

  if (type === responseType.Error) {
    yield put(changeResultFailureAction(data));
  }
}
