import * as React from 'react';
import { memo, useMemo } from 'react';

import { TablesClosingStatus, TablesStatus } from 'constants/tables';
import { LabelVariants } from 'atoms';
import { StatusLabelComponent } from './StatusLabel';

export interface IStatusLabel {
  status: TablesStatus;
}

export const StatusLabel = memo(({ status }: IStatusLabel) => {
  const statusPrint: string = useMemo(() => {
    if (status === TablesStatus.Open || status === TablesStatus.Taken) {
      return 'active';
    }
    if (status === TablesStatus.Maintenance) {
      return TablesClosingStatus.Maintenance;
    }
    return TablesClosingStatus.Inactive;
  }, [status]);

  const statusVariant = useMemo(() => {
    if (status === TablesStatus.Open || status === TablesStatus.Taken) {
      return LabelVariants.Active;
    }
    if (status === TablesStatus.Maintenance) {
      return LabelVariants.Maintenance;
    }
    return LabelVariants.Default;
  }, [status]);

  return <StatusLabelComponent variant={statusVariant} statusText={statusPrint} />;
});
