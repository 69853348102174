import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  tableTakenAction,
  connectToWebSocketFailureAction,
  connectToChatWebSocketFailureAction,
} from 'data/reducers';

export function* onSocketErrorSaga(message: string) {
  try {
    if (message === 'Table is already taken' || message.includes('is already taken')) {
      yield put(tableTakenAction());
    }
    yield put(connectToWebSocketFailureAction({ status: 404, message }));
  } catch (error) {
    Sentry.captureException(error);
  }
}

export function* onChatSocketErrorSaga(message: string) {
  yield put(connectToChatWebSocketFailureAction({ status: 404, message }));
}
