import { DealerIdLength, ShiftManagerIdLength } from 'constants/common';
import React, {
  ChangeEvent,
  FormEvent,
  useCallback,
  useState,
  memo,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { ICustomError } from 'types';

import { PopupVerificationComponent } from './PopupVerification';

export enum PopupVerificationTypes {
  Dealer = 'Dealer',
  ShiftManager = 'ShiftManager',
}

export interface IPopupVerificationValues {
  id: string;
}

export interface IPopupVerification {
  title: string;
  type: PopupVerificationTypes;
  loading: boolean;
  error?: ICustomError | Error | null;
  clearError?: () => void;
  onCancel: () => void;
  onSubmit: (values: IPopupVerificationValues) => void;
}

export const PopupVerification = memo(
  ({ title, type, error, loading, clearError, onCancel, onSubmit }: IPopupVerification) => {
    const [formValues, setFormValues] = useState<IPopupVerificationValues>({ id: '' });
    const inputIdRef = useRef<HTMLInputElement>(null);
    const maxLengthInputValue: number | undefined = useMemo(() => {
      if (type === PopupVerificationTypes.ShiftManager) {
        return ShiftManagerIdLength;
      }

      if (type === PopupVerificationTypes.Dealer) {
        return DealerIdLength;
      }
    }, [type]);

    const onInputChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.currentTarget.value;
        const isNumeric = /^\d+$/.test(newValue);
        const isEmpty = newValue === '';

        if (isNumeric || isEmpty) {
          if (clearError) {
            clearError();
          }
          setFormValues((prevState) => ({ ...prevState, id: newValue }));
        }
      },
      [setFormValues, clearError],
    );

    const onKeypadClick = useCallback(
      (keyValue: string) => {
        if (clearError) {
          clearError();
        }
        setFormValues((prevState) => {
          if (maxLengthInputValue !== undefined && prevState.id.length === maxLengthInputValue) {
            return prevState;
          }

          const newState = {
            ...prevState,
            id: prevState.id.concat(keyValue),
          };

          return newState;
        });
        inputIdRef?.current?.focus();
      },
      [maxLengthInputValue, setFormValues, clearError],
    );

    const handleSubmit = useCallback(
      (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit(formValues);
      },
      [onSubmit, formValues],
    );

    const onClear = useCallback(() => {
      if (clearError) {
        clearError();
      }
      setFormValues({ id: '' });
    }, [setFormValues, clearError]);

    const popupLabel: string | undefined = useMemo(() => {
      if (type === PopupVerificationTypes.Dealer) {
        return 'Dealer ID';
      }

      if (type === PopupVerificationTypes.ShiftManager) {
        return 'Shift Manager ID';
      }
    }, [type]);

    const hideInputValue = useMemo(() => type === PopupVerificationTypes.ShiftManager, [type]);

    useEffect(() => {
      inputIdRef?.current?.focus();
    }, [inputIdRef]);

    const onClearHandler = useCallback(() => {
      onClear();
      inputIdRef?.current?.focus();
    }, [onClear]);

    return (
      <PopupVerificationComponent
        title={title}
        label={popupLabel}
        formValues={formValues}
        hideInputValue={hideInputValue}
        maxLengthInputValue={maxLengthInputValue}
        loading={loading}
        inputIdRef={inputIdRef}
        error={error as ICustomError}
        onCancel={onCancel}
        onInputChange={onInputChange}
        onKeypadClick={onKeypadClick}
        handleSubmit={handleSubmit}
        onClear={onClearHandler}
      />
    );
  },
);
