import React from 'react';
import styled from 'styled-components';

import { LogInForm } from 'molecules';

export const LogIn = () => (
  <LoginContainer>
    <LogInForm />
  </LoginContainer>
);

const LoginContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0 100px 0;
  box-sizing: border-box;
`;
