import { IState } from 'core/types';

export const getWebSocketConnection = ({ webSocket: { webSocketConnection } }: IState) =>
  webSocketConnection;

export const getTableOpened = ({ webSocket: { tableOpened } }: IState) => tableOpened;

export const getGameMachineConnection = ({ webSocket: { isGameMachineConnected } }: IState) =>
  isGameMachineConnected;

export const getSocketDisconnected = ({ webSocket: { isSocketDisconnected } }: IState) =>
  isSocketDisconnected;

export const getTableTaken = ({ webSocket: { isTableTaken } }: IState) => isTableTaken;
