import React from 'react';
import { IconNames } from 'components/atoms';
import { InfoPlaceholder } from 'constants/common';
import { IStageJackpot } from 'data/sagas/types';
import { Moment } from 'moment';
import Countdown from 'react-countdown';
import {
  Wrapper,
  TableDescription,
  Item,
  ItemInner,
  ItemLabel,
  ItemValue,
  ControlsWrapper,
  Buttons,
  UserButton,
  PowerButton,
  StageCountdown,
  StageText,
} from './styles';

export interface IProps {
  tableId: string;
  gameId?: string;
  dealerName: string;
  isChangeDealerDisabled: boolean;
  onChangeDealerClick: () => void;
  onCloseTableClick: () => void;
  className?: string;
  stage: IStageJackpot | null;
  // @ts-ignore
  // eslint-disable-next-line react/no-unused-prop-types
  dateTimer: Moment | null;
}

export const Head = ({
  tableId,
  gameId = InfoPlaceholder,
  dealerName,
  isChangeDealerDisabled,
  onChangeDealerClick,
  onCloseTableClick,
  className,
  stage,
  dateTimer,
}: IProps) => (
  <Wrapper className={className}>
    <TableDescription>
      <Item>
        <ItemInner>
          <ItemLabel>Table ID</ItemLabel>
          <ItemValue>{tableId}</ItemValue>
        </ItemInner>
      </Item>
      <Item>
        <ItemInner>
          <ItemLabel>Game ID</ItemLabel>
          <ItemValue>{gameId}</ItemValue>
        </ItemInner>
      </Item>
      <Item>
        <ItemInner>
          <ItemLabel>Dealer</ItemLabel>
          <ItemValue>{dealerName}</ItemValue>
        </ItemInner>
        <UserButton
          disabled={isChangeDealerDisabled}
          onClick={onChangeDealerClick}
          iconName={IconNames.People}
        />
      </Item>
    </TableDescription>
    <ControlsWrapper>
      {(stage || dateTimer) && (
        // {stage && (
        <StageCountdown>
          {stage && (
            <StageText dominant-baseline="hanging">
              Stage {stage.stageNumber}{' '}
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M5.835 6.1348C7.44692 6.1348 8.7525 4.82922 8.7525 3.2173C8.7525 1.60539 7.44692 0.299805 5.835 0.299805C4.22308 0.299805 2.9175 1.60539 2.9175 3.2173C2.9175 4.82922 4.22308 6.1348 5.835 6.1348ZM5.835 7.59355C3.88757 7.59355 0 8.57092 0 10.5111V11.2404C0 11.6416 0.328219 11.9698 0.729375 11.9698H10.9406C11.3418 11.9698 11.67 11.6416 11.67 11.2404V10.5111C11.67 8.57092 7.78243 7.59355 5.835 7.59355Z"
                  fill="#FFF7EB"
                />
              </svg>
              {stage?.players?.length}
            </StageText>
          )}
          {dateTimer && !stage && (
            <Countdown
              date={dateTimer?.toDate()}
              renderer={({ days, hours, minutes, seconds }) => (
                <StageText dominant-baseline="hanging">
                  {hours + days * 24 < 10 ? `0${hours}` : hours + days * 24} :{' '}
                  {minutes < 10 ? `0${minutes}` : minutes} :{' '}
                  {seconds < 10 ? `0${seconds}` : seconds}
                </StageText>
              )}
            />
          )}
        </StageCountdown>
      )}
      <Buttons>
        <PowerButton onClick={onCloseTableClick} iconName={IconNames.Power} />
      </Buttons>
    </ControlsWrapper>
  </Wrapper>
);
