import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';
import { ButtonSizes, ButtonVariants, IButton } from './Button';

export const ButtonContainer = styled.button<IButton>`
  position: relative;
  ${({ size }) => {
    if (size === ButtonSizes.md) {
      return css`
        ${fontService.text({ size: 16, weight: FontWeight.extraBold })};
        min-height: 56px;
      `;
    }
    if (size === ButtonSizes.sm) {
      return css`
        ${fontService.text({ size: 14, weight: FontWeight.extraBold })};
        min-height: 48px;
      `;
    }
    if (size === ButtonSizes.lg) {
      return css`
        ${fontService.text({ size: 24, weight: FontWeight.extraBold })};
        min-height: 64px;
      `;
    }
  }};
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
  cursor: pointer;
  transition: opacity 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  letter-spacing: 0.02em;
  padding-right: ${({ sidePaddings }) => `${sidePaddings}px`};
  padding-left: ${({ sidePaddings }) => `${sidePaddings}px`};
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  text-transform: uppercase;
  &:focus {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ disabled, loading, theme: { text } }) => css`
    color: ${disabled && !loading ? text.primaryDisabled : text.primaryDefault};
  `};

  ${({ variant, disabled, loading, theme: { button } }) => {
    if (disabled && !loading) {
      return css`
        border-color: ${button.disabledBorder};
        background-color: ${button.disabled};
      `;
    }

    switch (variant) {
      case ButtonVariants.Accept:
        if (loading) {
          return css`
            border-color: ${button.acceptBorder};
            background-color: ${button.accept};
          `;
        }
        return css`
          border-color: ${button.acceptBorder};
          background-color: ${button.accept};

          &:hover {
            border-color: ${button.acceptHoverBorder};
            background-color: ${button.acceptHover};
          }
        `;
      case ButtonVariants.Cancel:
        if (loading) {
          return css`
            border-color: ${button.cancelBorder};
            background-color: ${button.cancel};
          `;
        }
        return css`
          border-color: ${button.cancelBorder};
          background-color: ${button.cancel};

          &:hover {
            border-color: ${button.cancelHoverBorder};
            background-color: ${button.cancelHover};
          }
        `;
      case ButtonVariants.Error:
        if (loading) {
          return css`
            border-color: ${button.errorBorder};
            background-color: ${button.error};
          `;
        }
        return css`
          border-color: ${button.errorBorder};
          background-color: ${button.error};

          &:hover {
            border-color: ${button.errorHoverBorder};
            background-color: ${button.errorHover};
          }
        `;
      case ButtonVariants.Info:
        if (loading) {
          return css`
            border-color: ${button.infoBorder};
            background-color: ${button.info};
          `;
        }
        return css`
          border-color: ${button.infoBorder};
          background-color: ${button.info};

          &:hover {
            border-color: ${button.infoHoverBorder};
            background-color: ${button.infoHover};
          }
        `;
      default:
        if (loading) {
          return css`
            border-color: ${button.primaryBorder};
            background-color: ${button.primary};
          `;
        }
        return css`
          border-color: ${button.primaryBorder};
          background-color: ${button.primary};

          &:hover {
            border-color: ${button.primaryHoverBorder};
            background-color: ${button.primaryHover};
          }
        `;
    }
  }};
`;
