import { put, takeLatest, delay, select } from 'redux-saga/effects';
import { getGameData } from 'core/selectors';
import { ACTIONS } from 'data/constants';
import {
  decrementBettingTimeAction,
  incrementDrawTimeAction,
  stopDecrementBettingTimeAction,
  stopIncrementDrawTimeAction,
} from 'data/reducers';

function* decrementBettingTimeSaga() {
  while (true) {
    const {
      round: {
        timerLeft: { bettingTimeLeft },
      },
    } = yield select(getGameData);

    if (bettingTimeLeft > 1) {
      yield delay(1000);
      yield put(decrementBettingTimeAction());
    } else {
      yield put(stopDecrementBettingTimeAction());
      break;
    }
  }
}

function* incrementDrawTimeSaga() {
  while (true) {
    const {
      round: {
        timerLeft: { drawTimeLeft },
      },
    } = yield select(getGameData);

    if (drawTimeLeft >= 0) {
      yield delay(1000);
      yield put(incrementDrawTimeAction());
    } else {
      yield put(stopIncrementDrawTimeAction());
      break;
    }
  }
}

export function* watchDecrementTimeLeftSaga() {
  yield takeLatest(ACTIONS.START_DECREMENT_BETTING_TIME, decrementBettingTimeSaga);
  yield takeLatest(ACTIONS.START_INCREMENT_DRAW_TIME, incrementDrawTimeSaga);
}
