import { Icon, IconNames } from 'components/atoms';
import React, { ReactNode } from 'react';
import { Backdrop, CloseButton, Container, Content, Head, Wrapper } from './styles';

export interface IProps {
  title: string;
  children: ReactNode;
  onClose?: () => void;
  className?: string;
  loading: boolean;
}

export const Popup = ({ title, children, loading, onClose, className }: IProps) => (
  <Wrapper className={className}>
    <Backdrop onClick={!loading ? onClose : undefined} />
    <Container>
      <Head>
        {title}
        <CloseButton onClick={!loading ? onClose : undefined}>
          <Icon name={IconNames.Clear} />
        </CloseButton>
      </Head>
      <Content>{children}</Content>
    </Container>
  </Wrapper>
);
