import React from 'react';
import styled from 'styled-components';
import { Field, InjectedFormProps } from 'redux-form';
import { ILogInFormValues } from 'types';
import { Button, Input } from 'atoms';
import { useRequestError } from 'hooks/useRequestError';
import { loginRequstAction } from 'data/reducers';
import fontService, { FontWeight } from 'services/FontService';

interface IInputProps {
  input: string;
  label: string;
  meta: {
    touched: boolean;
    error: string | undefined;
  };
  name: string;
}

const RenderEmailField = ({ input, label, meta: { touched, error }, name }: IInputProps) => {
  const errorText = touched && error ? error : '';
  const isError = Boolean(touched && error);
  const { error: serverError } = useRequestError(loginRequstAction);
  return (
    <StyledInput
      label={label}
      hasError={isError || Boolean(serverError)}
      message={errorText}
      name={name}
      placeholder="Please enter your email"
      {...(input as {})}
    />
  );
};

const RenderPasswordField = ({ input, label, meta: { touched, error }, name }: IInputProps) => {
  const errorText = touched && error ? error : '';
  const isError = Boolean(touched && error);
  const { error: serverError } = useRequestError(loginRequstAction);
  return (
    <Input
      label={label}
      hasError={isError || Boolean(serverError)}
      message={errorText}
      name={name}
      isPasswordType
      isPasswordAbleToBeShown
      placeholder="Please enter your password"
      {...(input as {})}
    />
  );
};

export const LogInForm = ({ handleSubmit }: InjectedFormProps<ILogInFormValues>) => {
  const { error: serverError } = useRequestError(loginRequstAction);
  return (
    <FormContainer>
      <form onSubmit={handleSubmit}>
        <FormHeader>Log into your account</FormHeader>
        <Field name="email" label="Email" component={RenderEmailField} />
        <Field name="password" label="Password" component={RenderPasswordField} />
        {serverError ? <ErrorMessage>Incorrect email or password</ErrorMessage> : null}
        <StyledButton type="submit">LOGIN</StyledButton>
      </form>
    </FormContainer>
  );
};

const FormContainer = styled.div`
  width: 464px;
  padding: 0 32px 0 32px;
  border-radius: 12px;
  background: ${({ theme: { themeBGColor } }) => themeBGColor};
  box-shadow: 0px 16px 40px rgba(18, 16, 17, 0.25);
`;

const FormHeader = styled.div`
  width: calc(100% + 64px);
  margin: 0 0 32px -32px;
  height: 72px;
  background: ${({ theme: { headerBGColor } }) => headerBGColor};
  box-shadow: inset 0px -1px 0px #bb9370;
  border-radius: 4px 4px 0px 0px;
  ${fontService.text({ size: 18, lineHeight: 222, weight: FontWeight.bold })};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  display: flex;
  align-items: center;
  padding-left: 32px;
  text-transform: uppercase;
`;

const StyledButton = styled(Button)`
  margin: 32px 0 48px 0;
`;

const StyledInput = styled(Input)`
  margin: 0px 0 24px 0;
`;

const ErrorMessage = styled.div`
  width: 100%;
  height: 32px;
  margin-top: 16px;
  padding: 8px 12px;
  background: ${({ theme: { errorBGColor } }) => errorBGColor};
  border-radius: 4px;
  ${fontService.text({ size: 12, lineHeight: 133, weight: FontWeight.regular })};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  letter-spacing: 0.02em;
`;
