import { put, select } from 'redux-saga/effects';

import { getAuthSelector } from 'core/selectors/authSelectors';
import { setJackpot } from 'data/reducers/AuthReducer';
import { Notifications } from 'types';
import { OnJackpotCreatedData } from './types';

export function* onJackpotUpdatedSaga({
  type: notificationType,
  data: jackpot,
}: OnJackpotCreatedData) {
  if (notificationType === Notifications.JACKPOT_UPDATED) {
    const { currentJackpot } = yield select(getAuthSelector);
    const isCurrent = jackpot.jackpotId === currentJackpot.jackpotId;

    if (isCurrent) {
      yield put(setJackpot(jackpot));
    }
  }
}
