import { put } from 'redux-saga/effects';

import { ChatDealerJoinedResponse, NotificationsChat } from 'types';
import { connectToChatWebSocketSuccessAction, saveChatMessagesAction } from 'data/reducers';

export function* chatDealerJoined({ type: responseType, data }: ChatDealerJoinedResponse) {
  if (responseType === NotificationsChat.CHAT_DEALER_CONNECTED) {
    yield put(connectToChatWebSocketSuccessAction());
    if (data.chatHistory) {
      yield put(saveChatMessagesAction(data.chatHistory));
    }
  }
}
