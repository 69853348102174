import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import { isEmpty } from 'ramda';

import {
  openTableSuccessAction,
  changeGameStateAction,
  saveGameDataToStore,
  changeGameStatusAction,
} from 'data/reducers';
import { Notifications, TableOpenedResponse, TableStates } from 'types';
import { gameStatus } from 'constants/common';

export function* tableOpened({ type: responseType, data }: TableOpenedResponse) {
  try {
    if (responseType === Notifications.TABLE_OPENED) {
      yield put(changeGameStateAction({ tableState: TableStates.TableOpened }));
      yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
      yield put(openTableSuccessAction());

      if (data.state && !isEmpty(data.state)) {
        yield put(saveGameDataToStore({ selectedTableData: data.state }));
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
