import { Icon } from 'components/atoms';
import { colors } from 'constants/theme';
import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';
import { ToastTypes } from 'types';
import { AlertAssignments } from './Alert';

export const Message = styled.div`
  ${fontService.getFont({ size: 16, lineHeight: 125, weight: FontWeight.medium })};

  ${({ theme: { text } }) => css`
    color: ${text.primaryDefault};
  `};
`;

export const Wrapper = styled.div<{
  variant: ToastTypes;
  showIcon: boolean;
  assignment: AlertAssignments;
}>`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 4px;

  ${({ assignment }) => {
    if (assignment === AlertAssignments.Icon) {
      return css`
        padding: 8px;
        border-radius: 8px;
        border: 1px solid rgba(255, 199, 0, 0.64);
      `;
    }
  }}

  ${({ showIcon }) =>
    showIcon
      ? css`
          ${Message} {
            margin-left: 8px;
          }
        `
      : null};

  ${({ variant }) => {
    if (variant === ToastTypes.SUCCESS) {
      return css`
        background-color: ${colors.Green}A3;
      `;
    }

    if (variant === ToastTypes.WARNING) {
      return css`
        background-color: ${colors.AccentYellow}A3;
      `;
    }

    if (variant === ToastTypes.ERROR) {
      return css`
        background-color: ${colors.Red}A3;
      `;
    }
  }}
`;

export const AlertIcon = styled(Icon)`
  height: 24px;
  width: 24px;
`;
