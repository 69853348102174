import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;
  overflow: hidden;

  ${({ theme: { button } }) => css`
    border-color: ${button.primaryBorderLight};
  `};
`;

export const KeyButton = styled.button`
  width: 100%;
  padding: 28px 30px;
  border: none;
  ${fontService.text({ size: 24, lineHeight: 100, weight: FontWeight.bold })};
  text-align: center;
  background: unset;
  user-select: none;
  cursor: pointer;

  ${({ theme: { text, button } }) => css`
    box-shadow: 0 0 0 1px ${button.cancelBorder};
    color: ${text.secondaryDefault};

    &:hover {
      box-shadow: 0 0 0 1px ${button.primaryBorderLightHover};
      background: linear-gradient(180deg, rgba(52, 52, 52, 0) 0%, #343434 99.99%, #343434 100%);
      color: ${text.primaryDefault};
    }

    &:active {
      box-shadow: 0 0 0 1px ${button.primary};
      background: linear-gradient(180deg, rgba(187, 147, 112, 0) 0%, #343434 99.99%, #343434 100%);
      color: ${text.secondaryDefault};
    }
  `};
`;

export const TableCell = styled.td`
  width: 20%;
`;

export const TableRow = styled.tr`
  &:first-of-type {
    & > ${TableCell}:first-of-type ${KeyButton} {
      border-radius: 8px 0 0 0;
    }

    & > ${TableCell}:last-of-type ${KeyButton} {
      border-radius: 0 8px 0 0;
    }
  }

  &:last-of-type {
    & > ${TableCell}:first-of-type ${KeyButton} {
      border-radius: 0 0 0 8px;
    }

    & > ${TableCell}:last-of-type ${KeyButton} {
      border-radius: 0 0 8px 0;
    }
  }
`;

export const Table = styled.table<{ disabled: boolean }>`
  table-layout: fixed;
  width: 100%;
  border-spacing: 0;
  background: linear-gradient(180deg, rgba(18, 16, 17, 0) 0%, #2b2a2b 100%);

  ${({ disabled, theme: { text, button } }) => {
    if (disabled) {
      return css`
        & > ${TableRow} > ${TableCell} > ${KeyButton} {
          color: ${text.secondaryDisabledLight};
          cursor: not-allowed;

          &:hover {
            box-shadow: 0 0 0 1px ${button.cancelBorder};
            background: unset;
            color: ${text.secondaryDisabledLight};
          }
        }
      `;
    }
  }};
`;
