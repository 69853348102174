import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  roundCanceledAction,
  changeGameStateAction,
  changeGameStatusAction,
  addToastAction,
} from 'data/reducers';
import { Notifications, RoundCanceledResponse, TableStates, ToastTypes } from 'types';
import { gameStatus, alerts } from 'constants/common';

export function* roundCanceled({ type: notificationType, data: result }: RoundCanceledResponse) {
  try {
    if (notificationType === Notifications.ROUND_CANCELED) {
      yield put(changeGameStateAction({ tableState: TableStates.RoundCanceled }));
      yield put(changeGameStatusAction({ status: gameStatus.OpenRound }));
      yield put(roundCanceledAction());
      if (result.fromAdmin) {
        yield put(addToastAction({ type: ToastTypes.WARNING, value: alerts.ROUND_CANCELED }));
      }
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
