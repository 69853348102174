import { IChatMessage } from 'types/chat';

export const queue = (array: string[], item: string) => {
  if (array.length >= 10) {
    const result = [...array, item];
    return result.slice(-10);
  }
  return [...array, item];
};

export const messagesQueue = (array: IChatMessage[], item: IChatMessage) => {
  if (array.length === 50) {
    const result = [...array, item];
    return result.slice(1);
  }
  return [...array, item];
};
