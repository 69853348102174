import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getTables } from 'core/selectors';
import {
  clearGameDataAction,
  clearSagaChannels,
  clearSelectedTableToStore,
  closeTableAction,
  disconnectFromWebSocketAction,
  getTablesRequest,
} from 'data/reducers';
import { useRequestLoading } from 'hooks/useRequestLoading';

import { Tables } from './Tables';

export const Container = () => {
  const dispatch = useDispatch();
  const tablesData = useSelector(getTables);
  const loadingTables = useRequestLoading(getTablesRequest);

  useEffect(() => {
    dispatch(getTablesRequest());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearGameDataAction());
    dispatch(clearSelectedTableToStore());
    dispatch(disconnectFromWebSocketAction());
    dispatch(clearSagaChannels());
    dispatch(closeTableAction());
  }, [dispatch]);

  return <Tables tablesData={tablesData} loading={loadingTables} />;
};
