import { TablesClosingStatus } from 'constants/tables';
import React, { useCallback, useState } from 'react';
import { SelectOptionType } from 'types';
import { PopupClosingTableComponent } from './PopupClosingTable';

interface IProps {
  options: SelectOptionType<TablesClosingStatus>[];
  loading: boolean;
  onSubmit: (value: TablesClosingStatus) => void;
  onClose: () => void;
}

export const PopupClosingTable = ({ options, loading, onSubmit, onClose }: IProps) => {
  const [closingStatus, setClosingStatus] = useState<TablesClosingStatus>();

  const onChange = useCallback((value: TablesClosingStatus) => {
    setClosingStatus(value);
  }, []);

  const onConfirmClick = useCallback(() => {
    if (closingStatus !== undefined) {
      onSubmit(closingStatus);
    }
  }, [closingStatus, onSubmit]);

  return (
    <PopupClosingTableComponent
      options={options}
      value={closingStatus}
      loading={loading}
      onChange={onChange}
      onConfirmClick={onConfirmClick}
      onClose={onClose}
    />
  );
};
