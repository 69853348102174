import { useEffect, useState } from 'react';

type ConnectionStateType = {
  socket: WebSocket;
  lastEventTime: number;
  intervalId: any | null;
};

export const useStreamStatus = (streamName: string | null) => {
  const [isStreamOn, setIsStreamOn] = useState<boolean>(false);

  useEffect(() => {
    if (!streamName) return;

    const webSocketUrl = `wss://bintu-play.nanocosmos.de/h5live/stream/stream.mp4?url=rtmp://localhost/play&stream=${streamName}&cid=000000&pid=00000000000`;
    const connectionState: ConnectionStateType = {
      socket: new WebSocket(webSocketUrl),
      lastEventTime: 0,
      intervalId: null,
    };

    connectionState.socket.onmessage = (e) => {
      try {
        const message = JSON.parse(e.data);
        connectionState.lastEventTime = new Date().getTime();

        if (message.eventType === 'onStreamStatus') {
          if (!connectionState.intervalId) {
            connectionState.intervalId = setInterval(() => {
              const timeNow = new Date().getTime();

              if (timeNow - connectionState.lastEventTime > 3000) {
                connectionState.socket.close();

                const newSocket = new WebSocket(webSocketUrl);
                newSocket.onmessage = connectionState.socket.onmessage;
                connectionState.socket = newSocket;
                clearInterval(connectionState.intervalId);
                connectionState.intervalId = null;
              }
            }, 1000);
          }

          setIsStreamOn(message.onStreamStatus.status === 'started');
        }

        if (message.eventType !== 'onStreamStatus' && connectionState.intervalId) {
          clearInterval(connectionState.intervalId);
          connectionState.intervalId = null;
        }
      } catch (err) {
        console.log(e.toString());
      }
    };

    return () => {
      clearInterval(connectionState.intervalId);
      connectionState.socket.close();
    };
  }, [streamName]);

  return {
    isStreamOn,
  };
};
