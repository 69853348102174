import { createGlobalStyle, css } from 'styled-components';

export interface ITheme {
  theme: {
    [key: string]: string;
  };
}

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }

  p {
    margin: 0;
    padding: 0;
  }

  body {
    ${({ theme: { bodyBackground, primaryTextColor } }: ITheme) => css`
      #root {
        min-height: 100vh;
      }
      background: ${bodyBackground};
      color: ${primaryTextColor};
      width: 100vw;
      margin: 0;
      padding: 0;
      font-family: Roboto, BlinkMacSystemFont, -apple-system, 'Segoe UI', Helvetica, Arial,
        sans-serif;
      overflow-x: hidden;
    `}
  }
  
  p {
    margin: 0;
  }
  
`;
