import * as React from 'react';
import { memo } from 'react';

import { Label, LabelVariants } from 'atoms';

export interface IStatusLabelComponent {
  variant?: LabelVariants;
  statusText: string;
}

export const StatusLabelComponent = memo(({ variant, statusText }: IStatusLabelComponent) => (
  <Label variant={variant}>{statusText}</Label>
));
