import React from 'react';
import { reduxForm } from 'redux-form';
import { useDispatch } from 'react-redux';
import { IAuthFormErrors, ILogInFormValues } from 'types';
import { loginRequstAction } from 'data/reducers';
import { LogInForm } from './LogInForm';

export const Container = () => {
  const dispatch = useDispatch();
  const validate = ({ email, password }: ILogInFormValues) => {
    const errors: IAuthFormErrors = {};

    if (!email) {
      errors.email = 'Email required';
    }

    if (!password) {
      errors.password = 'Password required';
    }

    return errors;
  };

  const onSubmit = ({ email, password }: ILogInFormValues) => {
    dispatch(loginRequstAction({ email: email.trim(), password }));
  };

  const InitForm = reduxForm<ILogInFormValues, {}>({
    form: 'logInForm',
    touchOnBlur: false,
    validate,
    onSubmit,
  })(LogInForm);

  return <InitForm />;
};
