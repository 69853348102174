import React from 'react';
import { IconNames } from '../Icon';
import { ButtonIcon, ButtonStyled, ButtonText } from './styles';
import { ButtonPreloader } from '../Preloader';

export interface IProps {
  children: string;
  iconName: IconNames;
  disabled?: boolean;
  loading?: boolean;
  onClick: () => void;
  className?: string;
}

export const ButtonControl = ({
  children,
  iconName,
  disabled = false,
  loading = false,
  onClick,
  className,
}: IProps) => (
  <ButtonStyled onClick={onClick} disabled={disabled} className={className}>
    {loading ? (
      <ButtonPreloader />
    ) : (
      <>
        <ButtonIcon name={iconName} />
        <ButtonText>{children}</ButtonText>
      </>
    )}
  </ButtonStyled>
);
