import { TablesStatus } from 'constants/tables';
import { ITable, TableStates } from 'types/tables';
import { gameStatus } from 'constants/common';

export const tableMock: ITable = {
  id: '0',
  table_id: '',
  auto_round: false,
  auto_draw: false,
  auto_confirm_result: false,
  betting_time: 0,
  status: TablesStatus.Open,
  gameId: '-',
  dealer: {
    avatar: '',
    name: '',
    nickname: 'Dealer',
    tableId: '',
  },
  tableState: TableStates.TableLoading,
  statusText: gameStatus.Loading,
  round: {
    isFinished: false,
    drawResults: [],
  },
  toastItem: null,
  stream_low: null,
  chatMessages: [],
};
