import React from 'react';
import styled from 'styled-components';
import { TableCard } from 'molecules';
import { TableCommonType } from 'types';
import fontService, { FontWeight } from 'services/FontService';

import { Icon, IconNames, LoadingIndicator } from 'atoms';

interface IProps {
  tablesData: TableCommonType[];
  loading: boolean;
}

export const Tables = ({ tablesData, loading }: IProps) => (
  <div>
    <Header>
      <Text>Dealer User Interface ({process.env.REACT_APP_PLATFORM_VERSION})</Text>
    </Header>

    {tablesData.length ? (
      <Container>
        {tablesData.map((tableData) => (
          <TableCard key={tableData.table_id} tableData={tableData} />
        ))}
      </Container>
    ) : (
      <NoAvailableContainer>
        {loading ? (
          <LoadingIndicator />
        ) : (
          <>
            <Icon name={IconNames.NoAvailable} />
            <NoAvailableTitle>No available tables</NoAvailableTitle>
          </>
        )}
      </NoAvailableContainer>
    )}
  </div>
);

const Container = styled.div`
  width: 100%;
  max-width: 1152px;
  min-height: calc(100vh - 345px);
  margin-top: 16px;
  display: flex;
  margin: 16px auto 60px;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 21px;
  row-gap: 32px;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  z-index: 10;
`;

const NoAvailableContainer = styled.div`
  width: 100%;
  max-width: 1152px;
  min-height: calc(100vh - 345px);
  margin-top: 16px;
  display: flex;
  margin: 16px auto 0;
  flex-direction: column;
  row-gap: 64px;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  z-index: 10;
`;

const Header = styled.div`
  width: 100%;
  height: 48px;
  background: #121011;
`;

const Text = styled.p`
  width: 100%;
  height: 100%;
  max-width: 1152px;
  ${fontService.text({ size: 10, weight: FontWeight.regular })};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

const NoAvailableTitle = styled.p`
  ${fontService.text({ size: 24, weight: FontWeight.bold })};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  text-transform: uppercase;
`;
