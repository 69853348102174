import { put, takeEvery, delay, select } from 'redux-saga/effects';
import { setCountdownJackpot } from 'data/reducers/AuthReducer';
import { ACTIONS } from 'data/constants';
import moment from 'moment';
import { getAuthSelector } from './selectors/authSelectors';

function* checkDateAndDispatch() {
  try {
    const { currentJackpot, countdownJackpot } = yield select(getAuthSelector);

    if (!currentJackpot) {
      console.log('no currentJackpot');
      return null;
    }

    const currentDate = moment().utc();

    const timerDateCurrentJP = moment(currentJackpot.timerDate);
    const drawnDateCurrentJP = moment(currentJackpot.drawnDate);
    const isDateInRangeCountdown = currentDate.isBetween(timerDateCurrentJP, drawnDateCurrentJP);

    if (!countdownJackpot && isDateInRangeCountdown) {
      yield put(setCountdownJackpot(drawnDateCurrentJP));
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
}

export function* watchDatesControlSaga() {
  yield takeEvery(ACTIONS.WATCH_DATE_CONTROL_INTERVAL, checkDateAndDispatch);
  while (true) {
    yield delay(20000);
    yield put({ type: ACTIONS.WATCH_DATE_CONTROL_INTERVAL });
  }
}
