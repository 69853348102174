import fontService, { FontWeight } from 'services/FontService';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 12px;
`;

export const OptionLabel = styled.p`
  margin-top: 16px;
  ${fontService.getFont({ size: 16, lineHeight: 150, weight: FontWeight.bold })};
`;

export const OptionValue = styled.div`
  height: 64px;
  width: 64px;
`;

export const OptionWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 24px 30px;
  border-width: 1px;
  border-style: solid;
  border-radius: 8px;

  ${({ isActive, theme: { button, text } }) => {
    if (isActive) {
      return css`
        border-color: ${button.primaryBorderLightHover};
        color: ${text.primaryDefault};
      `;
    }

    return css`
      border-color: ${button.primaryBorderLight};
      color: ${text.secondaryDefault};
      cursor: pointer;
    `;
  }};
`;
