import { saveNewChatMessageAction } from 'data/reducers';
import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { NewChatMessageNotificationResponse, NotificationsChat } from 'types';

export function* newChatMessage({
  type: notificationType,
  data: result,
}: NewChatMessageNotificationResponse) {
  try {
    if (notificationType === NotificationsChat.NEW_CHAT_MESSAGE) {
      yield put(saveNewChatMessageAction(result));
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
