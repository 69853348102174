import { DealerType, TableCommonType, TableStates } from 'types';
import { IJackpot } from './authorization';
import { IChatMessage } from './chat';

export enum Notifications {
  MESSAGE = 'MESSAGE',
  DEALER_CONNECTED = 'DEALER_CONNECTED',
  OPEN_TABLE = 'OPEN_TABLE',
  TABLE_OPENED = 'TABLE_OPENED',
  TABLE_CLOSE_VERIFY = 'VERIFY_SHIFT_MANAGER',
  TABLE_CLOSE_VERIFIED = 'VERIFY_SHIFT_MANAGER',
  VERIFY_SHIFT_MANAGER = 'VERIFY_SHIFT_MANAGER',
  CLOSE_TABLE = 'CLOSE_TABLE',
  TABLE_CLOSED = 'TABLE_CLOSED',
  CHANGE_DEALER = 'CHANGE_DEALER',
  DEALER_CHANGED = 'DEALER_CHANGED',
  START_ROUND = 'START_ROUND',
  ROUND_STARTED = 'ROUND_STARTED',
  CANCEL_ROUND = 'CANCEL_ROUND',
  ROUND_CANCELED = 'ROUND_CANCELED',
  BETTING_TIME_STARTED = 'BETTING_TIME_STARTED',
  BETTING_TIME_FINISHED = 'BETTING_TIME_FINISHED',
  START_DRAW = 'START_DRAW',
  DRAW_STARTED = 'DRAW_STARTED',
  DRAW_FINISHED = 'DRAW_FINISHED',
  CONFIRM_RESULT = 'CONFIRM_RESULT',
  RESULT_CONFIRMED = 'RESULT_CONFIRMED',
  ROUND_FINISHED = 'ROUND_FINISHED',
  CHANGE_RESULT = 'CHANGE_RESULT',
  RESULT_CHANGED = 'RESULT_CHANGED',
  GAME_MACHINE_CONNECTION = 'GAME_MACHINE_CONNECTION',
  NOTIFICATION = 'NOTIFICATION',
  PONG = 'PONG',
  ERROR = 'ERROR',

  JACKPOT_CREATED = 'JACKPOT_CREATED',
  JACKPOT_UPDATED = 'JACKPOT_UPDATED',
  JACKPOT_CANCELED = 'JACKPOT_CANCELED',
  JACKPOT_STAGE_STARTED = 'JACKPOT_STAGE_STARTED',
  JACKPOT_FINISHED = 'JACKPOT_FINISHED',
  JACKPOT_STATE_UPDATED = 'JACKPOT_STATE_UPDATED',

  CHAT_TOKEN_PROVIDED = 'CHAT_TOKEN_PROVIDED',
}

export enum NotificationsChat {
  CHAT_DEALER_CONNECTED = 'CHAT_DEALER_CONNECTED',

  NEW_CHAT_MESSAGE = 'NEW_CHAT_MESSAGE',
}

export enum ClientSideNotifications {
  SOCKET_CONNECTED = 'connect',
  SOCKET_DISCONNECTED = 'disconnect',
}

export enum ErrorNotifications {
  CONNECT_ERROR = 'connect_error',
  ERROR = 'error',
}

export interface INotificationData {
  code: string;
  message: string;
  payload: object;
}

export interface INotificationResponse<T> {
  data: T;
  type: Notifications;
}

export interface INotificationChatResponse<T> {
  data: T;
  type: NotificationsChat;
}

export interface IDataResponse {
  status: number;
  message: string;
}

interface IDealerJoinedResponseData {
  tableId: string;
  state?: {
    dealer: DealerType;
    last_balls: string[];
    status: TableStates;
    jackpots?: IJackpot[];
    round?: {
      gameId: string;
      drawTime?: number;
      winner?: string;
      drawStartedAt?: string;
      startedAt?: string;
    };
    table: TableCommonType;
  };
}

interface ITableOpenedResponseData {
  state: TableCommonType;
}

interface ITableCloseVerifyResponseData {}

interface ITableClosedResponseData {}

interface IDealerChangedResponseData {
  dealer: DealerType;
}

interface IRoundStartedResponseData {
  round: {
    gameId: string;
  };
}

interface ISocketPongData {
  token: string;
}

interface IRoundCanceledResponseData {
  fromAdmin: boolean;
}

interface IBettingTimeStartedResponseData {
  bettingTime: number;
}

interface IBettingTimeFinishedResponseData {}

interface IDrawStartedResponseData {
  drawStartedAt: string;
}

interface IDrawFinishedResponseData {
  winner: string;
  drawTime: number;
}

interface IResultConfirmedResponseData {
  winner: string;
}
interface IResultChangedResponseData {}

interface IRoundFinishedResponseData {}

interface IGameMachineConnectionResponseResponseData {
  status: 'CONNECTED' | 'DISCONNECTED';
}

interface IErrorNotificationResponseData {
  event: string;
  reason: string;
  status: number;
}

interface IChatTokenProvidedNotificationResponse {
  tableId: string;
  token: string;
}

interface IChatDealerJoinedResponseData {
  tableId: string;
  chatHistory: IChatMessage[];
}

interface INewChatMessageNotificationResponse {
  playerName: string;
  message: string;
  fromSupport: boolean;
}

export type DealerJoinedResponse = INotificationResponse<IDealerJoinedResponseData>;
export type TableOpenedResponse = INotificationResponse<ITableOpenedResponseData>;
export type TableCloseVerifyResponse = INotificationResponse<ITableCloseVerifyResponseData>;
export type TableClosedResponse = INotificationResponse<ITableClosedResponseData>;
export type DealerChangedResponse = INotificationResponse<IDealerChangedResponseData>;
export type RoundStartedResponse = INotificationResponse<IRoundStartedResponseData>;
export type RoundCanceledResponse = INotificationResponse<IRoundCanceledResponseData>;
export type BettingTimeStartedResponse = INotificationResponse<IBettingTimeStartedResponseData>;
export type BettingTimeFinishedResponse = INotificationResponse<IBettingTimeFinishedResponseData>;
export type DrawStartedResponse = INotificationResponse<IDrawStartedResponseData>;
export type DrawFinishedResponse = INotificationResponse<IDrawFinishedResponseData>;
export type ResultConfirmedResponse = INotificationResponse<IResultConfirmedResponseData>;
export type ResultChangedResponse = INotificationResponse<IResultChangedResponseData>;
export type RoundFinishedResponse = INotificationResponse<IRoundFinishedResponseData>;
export type GameMachineConnectionResponse =
  INotificationResponse<IGameMachineConnectionResponseResponseData>;
export type ChatTokenProvidedResponse =
  INotificationResponse<IChatTokenProvidedNotificationResponse>;
export type ChatDealerJoinedResponse = INotificationChatResponse<IChatDealerJoinedResponseData>;
export type NewChatMessageNotificationResponse =
  INotificationChatResponse<INewChatMessageNotificationResponse>;
export type SocketPongResponse = INotificationResponse<ISocketPongData>;
export type ErrorNotificationResponse = INotificationResponse<IErrorNotificationResponseData>;

export type PossibleResponse =
  | DealerJoinedResponse
  | TableOpenedResponse
  | TableCloseVerifyResponse
  | TableClosedResponse
  | DealerChangedResponse
  | RoundStartedResponse
  | RoundCanceledResponse
  | BettingTimeStartedResponse
  | BettingTimeFinishedResponse
  | DrawStartedResponse
  | DrawFinishedResponse
  | ResultConfirmedResponse
  | RoundFinishedResponse
  | ChatTokenProvidedResponse
  | ChatDealerJoinedResponse
  | NewChatMessageNotificationResponse
  | ErrorNotificationResponse;
