import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useOnRequestSuccess } from 'hooks/useOnRequestSuccess';
import { loginRequstAction } from 'data/reducers';

import { LogIn } from './LogIn';

export const Container = () => {
  const navigate = useNavigate();

  useOnRequestSuccess(loginRequstAction, () => {
    navigate('/tables', { replace: true });
  });

  return <LogIn />;
};
