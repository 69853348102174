import * as React from 'react';
import { memo, useMemo } from 'react';

import { LabelVariants } from 'atoms';
import { ConnectionStatusLabelComponent } from './ConnectionStatusLabel';

export interface IConnectionStatusLabel {
  isConnected: boolean;
  statusText: string;
}

export const ConnectionStatusLabel = memo(({ isConnected, statusText }: IConnectionStatusLabel) => {
  const statusVariant = useMemo(() => {
    if (isConnected) {
      return LabelVariants.Active;
    }

    return LabelVariants.Error;
  }, [isConnected]);

  const statusPrint = useMemo(() => {
    if (isConnected) {
      return `${statusText} ON`;
    }

    return `${statusText} OFF`;
  }, [isConnected, statusText]);

  return <ConnectionStatusLabelComponent variant={statusVariant} statusText={statusPrint} />;
});
