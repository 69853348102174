import { IPlayer, IStageJackpot } from 'data/sagas/types';
import { Moment } from 'moment';

export interface ILogInFormValues {
  email: string;
  password: string;
}

export interface ILogInToSocketValues {
  tableId: string;
  shiftManagerId: string;
}

export enum PositionJackpot {
  CURRENT = 'current',
  NEXT = 'next',
}

export interface IPlayerJP {
  name: string;
  value: number;
  uuid: string;
}

export interface IJackpot {
  jackpotId: string;
  currency: string;
  amount: number;
  collectionPeriodStartDate: string;
  collectionPeriodEndDate: string;
  drawnDate: string;
  timerDate: string;
  position: PositionJackpot.CURRENT;
  minBet: {
    currency: string;
    value: number;
  };

  isCollectionPeriod?: boolean;
  isPaused?: boolean;
  draw?: IStageJackpot;
  players?: IPlayerJP[];
  winDuration?: number;
}

export interface IAuthState {
  isLoggedIn: boolean;
  stage: IStageJackpot | null;
  winnersListJackpot: IPlayer[] | null;
  countdownJackpot: Moment | null;
  sumJackpotUserWon: number | null;
  currentJackpot: IJackpot | null;
}

export interface IAuthFormErrors {
  [key: string]: string;
}
