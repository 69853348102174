import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getGameData } from 'core/selectors';
import {
  changeTimeLeftAction,
  startDecrementBettingTimeAction,
  startIncrementDrawTimeAction,
  stopDecrementBettingTimeAction,
  stopIncrementDrawTimeAction,
} from 'data/reducers';
import { getBettingTimeLeft, getDrawTimeLeft } from 'helpers';

type TimerHookType = {
  bettingTimer?: number;
  drawTimer?: number;
  startBettingTimer: () => void;
  reloadBettingTimer: () => void;
  startDrawTimer: () => void;
  reloadDrawTimer: () => void;
};

export const useTimer = (): TimerHookType => {
  const dispatch = useDispatch();

  const { betting_time, round } = useSelector(getGameData);
  const [startedBettingTimer, setStartedBettingTimer] = useState<boolean>(false);
  const [startedDrawTimer, setStartedDrawTimer] = useState<boolean>(false);

  const startBettingTimer = useCallback(() => {
    setStartedBettingTimer(true);
    dispatch(startDecrementBettingTimeAction());
  }, [dispatch]);

  const startDrawTimer = useCallback(() => {
    setStartedDrawTimer(true);
    dispatch(startIncrementDrawTimeAction());
  }, [dispatch]);

  const reloadBettingTimer = useCallback(() => {
    setStartedBettingTimer(false);
    dispatch(changeTimeLeftAction({ bettingTimeLeft: undefined }));
  }, [dispatch]);

  const reloadDrawTimer = useCallback(() => {
    setStartedDrawTimer(false);
    dispatch(changeTimeLeftAction({ drawTimeLeft: undefined }));
  }, [dispatch]);

  useEffect(
    () => () => {
      dispatch(stopDecrementBettingTimeAction());
      dispatch(stopIncrementDrawTimeAction());
    },
    [dispatch],
  );

  useEffect(() => {
    if (!startedBettingTimer) {
      dispatch(stopDecrementBettingTimeAction());
    }
  }, [startedBettingTimer, dispatch]);

  useEffect(() => {
    if (!startedDrawTimer) {
      dispatch(stopIncrementDrawTimeAction());
    }
  }, [startedDrawTimer, dispatch]);

  useEffect(() => {
    if (!startedBettingTimer) {
      return;
    }

    const onVisibilityChange = () => {
      if (document.hidden) {
        console.log('User has left the page on BettingTimeStarted.');
        dispatch(stopDecrementBettingTimeAction());
      } else {
        console.log('User has returned to the page on BettingTimeStarted.');
        dispatch(
          changeTimeLeftAction({
            bettingTimeLeft: getBettingTimeLeft(round.startedAt, betting_time),
          }),
        );
        dispatch(startDecrementBettingTimeAction());
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, [dispatch, round.startedAt, betting_time, startedBettingTimer]);

  useEffect(() => {
    if (!startedDrawTimer) {
      return;
    }

    const onVisibilityChange = () => {
      if (document.hidden) {
        console.log('User has left the page on DrawStarted.');
        dispatch(stopIncrementDrawTimeAction());
      } else {
        console.log('User has returned to the page on DrawStarted.');
        dispatch(changeTimeLeftAction({ drawTimeLeft: getDrawTimeLeft(round.drawStartedAt) }));
        dispatch(startIncrementDrawTimeAction());
      }
    };

    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, [dispatch, round.drawStartedAt, startedDrawTimer]);

  return {
    startBettingTimer,
    reloadBettingTimer,
    startDrawTimer,
    reloadDrawTimer,
  };
};
