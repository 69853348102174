import { ActionType, createAction, createReducer } from 'typesafe-actions';

import { IAuthState, ILogInFormValues, ILogInToSocketValues } from 'types';

import { IJackpotWon, IStageJackpot } from 'data/sagas/types';
import { IJackpot } from 'types/authorization';
import { ACTIONS } from '../constants';

export const refreshTokenRequstAction = createAction(ACTIONS.REFRESH_TOKEN_REQUEST)();

export const loginRequstAction = createAction(ACTIONS.LOGIN_REQUEST)<ILogInFormValues>();
export const loginSuccessAction = createAction(ACTIONS.LOGIN_SUCCESS)();
export const loginFailureAction = createAction(ACTIONS.LOGIN_FAILURE)<any>();

export const loginToSocketRequestAction = createAction(
  ACTIONS.LOGIN_TO_SOCKET_REQUEST,
)<ILogInToSocketValues>();
export const loginToSocketSuccessAction = createAction(ACTIONS.LOGIN_TO_SOCKET_SUCCESS)();
export const loginToSocketFailureAction = createAction(ACTIONS.LOGIN_TO_SOCKET_FAILURE)<any>();

export const logOutAction = createAction(ACTIONS.LOGOUT)();
export const putLogInToStoreAction = createAction(ACTIONS.PUT_LOGIN_TO_STORE)<boolean>();

export const setStageJackpot = createAction(ACTIONS.SET_STAGE_JACKPOT)<IStageJackpot | null>();
export const setJackpot = createAction(ACTIONS.SET_JACKPOT)<IJackpot | null>();

export const setJackpotWon = createAction(ACTIONS.SET_JACKPOT_WON)<IJackpotWon>();

export const setCountdownJackpot = createAction(ACTIONS.SET_COUNTDOWN_JACKPOT)<any | null>();

export const defaultState: IAuthState = {
  isLoggedIn: false,
  stage: null,
  winnersListJackpot: null,
  countdownJackpot: null,
  sumJackpotUserWon: null,
  currentJackpot: null,
};

const actions = {
  loginSuccessAction,
  putLogInToStoreAction,
  setStageJackpot,
  setJackpotWon,
  setCountdownJackpot,
  setJackpot,
};

export const authReducer = createReducer<IAuthState, ActionType<typeof actions>>(defaultState)
  .handleAction(loginSuccessAction, (state) => ({
    ...state,
    isLoggedIn: true,
  }))
  .handleAction(putLogInToStoreAction, (state, { payload: isLoggedIn }) => ({
    ...state,
    isLoggedIn,
  }))
  .handleAction(setStageJackpot, (state, { payload: stage }) => ({
    ...state,
    stage,
    countdownJackpot: null,
  }))
  .handleAction(
    setJackpotWon,
    (state, { payload: { players: winnersListJackpot, amount: sumJackpotUserWon } }) => ({
      ...state,
      winnersListJackpot,
      sumJackpotUserWon,
    }),
  )
  .handleAction(setCountdownJackpot, (state, { payload: countdownJackpot }) => ({
    ...state,
    countdownJackpot,
  }))
  .handleAction(setJackpot, (state, { payload: currentJackpot }) => ({
    ...state,
    currentJackpot,
  }));
