import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { store } from 'core';
import './index.css';
import { AuthService, TableService } from 'services';
import { App } from './App';
import reportWebVitals from './reportWebVitals';

AuthService.setCredentials({ URL: process.env.REACT_APP_API_URL || 'https://dealer-api.com/' });
TableService.setCredentials({ URL: process.env.REACT_APP_API_URL || 'https://dealer-api.com/' });
AuthService.prefix = 'auth/';

Sentry.init({
  dsn: 'https://696bcbb977f27da484f6cce4c3d76cf3@o4507423836864512.ingest.de.sentry.io/4507458316468304',
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENV_NAME,
});

Sentry.setTag('version', process.env.REACT_APP_PLATFORM_VERSION);
Sentry.setTag('language_browser', Intl.DateTimeFormat().resolvedOptions().timeZone);

const container = document.getElementById('root')!;
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
