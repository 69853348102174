import { ResultBall } from 'types';

export const InfoPlaceholder = '-';

export const ShiftManagerIdLength = 5;
export const DealerIdLength = 3;

export const gameStatus = {
  OpenRound: 'Please, open round',
  BettingTime: 'Betting time',
  StartDraw: 'No more bets. Please, start draw',
  StartedDraw: 'Draw was started',
  ConfirmResult: 'Ball was drawn at',
  DisplayingWin: 'Displaying win ball',
  Loading: 'Loading...',
};

export const ballOptions = [
  { value: ResultBall.BLUE, label: 'Blue' },
  { value: ResultBall.RED, label: 'Red' },
  { value: ResultBall.GOLD, label: 'Gold' },
];
export const responseType = {
  Error: 'ERROR',
  Ok: 'OK',
};

export const errorMessages = {
  IncorrectId: 'Incorrect ID',
  NotAvailableId: 'Not available ID',
  NotAvailableAction: 'Not available action',
  NoConnectionToMachine: 'No connection to machine',
  NoConnectionToAdmin: 'No connection to Admin Panel',
};

export const alerts = {
  TABLE_CLOSED: {
    text: `Table will be closed after round's end!`,
  },
  ROUND_CANCELED: {
    text: 'Last round was canceled in Admin Panel',
  },
};
