import { put } from 'redux-saga/effects';
import { Notifications, ChatTokenProvidedResponse } from 'types';

import { connectToChatWebSocketAction } from 'data/reducers';

export function* chatTokenProvided({ type: notificationType, data }: ChatTokenProvidedResponse) {
  if (notificationType === Notifications.CHAT_TOKEN_PROVIDED) {
    yield put(connectToChatWebSocketAction({ token: data.token }));
  }
}
