import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
  clearSagaChannels,
  disconnectFromWebSocketAction,
  openTableFailureAction,
} from 'data/reducers';
import { ErrorNotificationResponse } from 'types';

export function* onErrorNotificationSaga({ data }: ErrorNotificationResponse) {
  try {
    if (data.event === 'OPEN_TABLE') {
      yield put(
        openTableFailureAction({
          status: data.status,
          message: data.reason,
        }),
      );
      yield put(disconnectFromWebSocketAction());
      yield put(clearSagaChannels());
    }
  } catch (error) {
    Sentry.captureException(error);
  }
}
