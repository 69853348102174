import React, { useCallback, useEffect, memo, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { createPortal } from 'react-dom';

import {
  clearSelectedTableToStore,
  getTableRequest,
  loginToSocketRequestAction,
  openTableAction,
  disconnectFromWebSocketAction,
  connectToWebSocketAction,
  tableNoTakenAction,
} from 'data/reducers';

import {
  getSelectedTableData,
  getTableOpened,
  getWebSocketConnection,
  getTableTaken,
} from 'core/selectors';

import { useModal } from 'hooks/useModal';
import { useRequestError, useRequestLoading } from 'hooks';
import { PopupVerification, PopupVerificationTypes } from 'components/molecules';
import { TablesStatus } from 'constants/tables';
import { AxiosError } from 'axios';
import { OpenTable } from './OpenTable';

export const Container = memo(() => {
  const { isShown: isModalShown, toggle } = useModal();
  const [shiftManagerId, setShiftManagerId] = useState<string | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: tableId } = useParams();

  const selectedTableData = useSelector(getSelectedTableData);
  const isTableTaken = useSelector(getTableTaken);
  const tableOpened = useSelector(getTableOpened);
  const isWebSocketConnection = useSelector(getWebSocketConnection);

  const openTableLoading = useRequestLoading(openTableAction);
  const loginToSocketLoading = useRequestLoading(loginToSocketRequestAction);
  const connectToWebSocketLoading = useRequestLoading(connectToWebSocketAction);

  const { error: errorTable, clearError: clearErrorTable } = useRequestError(getTableRequest);

  const { error, clearError } = useRequestError(loginToSocketRequestAction);
  const { error: errorOpenTable, clearError: clearErrorOpenTable } =
    useRequestError(openTableAction);

  const errorTableResponse = errorTable as AxiosError<{ status: number; statusText: string }>;

  const customError = useMemo(() => {
    if (error) {
      return {
        error: {
          status: 403,
          message: error.message,
        },
        clearError,
      };
    }
    if (errorOpenTable) {
      return {
        error: errorOpenTable,
        clearError: clearErrorOpenTable,
      };
    }

    return null;
  }, [error, errorOpenTable, clearError, clearErrorOpenTable]);

  const onOpenTableClick = useCallback(() => {
    toggle();
  }, [toggle]);

  const onIdSubmit = useCallback(
    (formValues: { id: string }) => {
      dispatch(loginToSocketRequestAction({ tableId: tableId!, shiftManagerId: formValues.id }));
      setShiftManagerId(formValues.id);
    },
    [dispatch, tableId],
  );

  const onBackToChooseClick = useCallback(() => {
    dispatch(clearSelectedTableToStore());
    dispatch(disconnectFromWebSocketAction());
    dispatch(tableNoTakenAction());
    navigate('/tables', { replace: true });
  }, [navigate, dispatch]);

  useEffect(() => {
    if (!selectedTableData && tableId) {
      dispatch(getTableRequest({ id: tableId }));
    }
  }, [selectedTableData, tableId, dispatch]);

  useEffect(() => {
    if (
      errorTableResponse?.response?.status === 404 &&
      errorTableResponse?.response?.statusText === 'Not Found'
    ) {
      clearErrorTable();
      navigate(`/not_found`, { replace: true });
    }
  }, [navigate, clearErrorTable, errorTableResponse]);

  useEffect(() => {
    if (tableOpened) {
      navigate(`/table/${tableId}`, { replace: true });
    }
  }, [tableOpened, tableId, navigate, dispatch]);

  useEffect(() => {
    if (isWebSocketConnection && !tableOpened) {
      dispatch(openTableAction({ id: shiftManagerId! }));
    }
  }, [isWebSocketConnection, shiftManagerId, tableOpened, dispatch]);

  useEffect(() => {
    if (isModalShown) {
      clearError();
      clearErrorOpenTable();
    }
  }, [isModalShown, clearError, clearErrorOpenTable]);

  useEffect(() => {
    if (isTableTaken) {
      toggle();
    }
  }, [isTableTaken, toggle]);

  return (
    <>
      {isModalShown
        ? createPortal(
            <PopupVerification
              title={
                selectedTableData?.status === TablesStatus.Open ? 'Reopen table' : 'Open table'
              }
              type={PopupVerificationTypes.ShiftManager}
              error={customError?.error}
              loading={openTableLoading || loginToSocketLoading || connectToWebSocketLoading}
              clearError={customError?.clearError}
              onSubmit={onIdSubmit}
              onCancel={toggle}
            />,
            document.body,
          )
        : null}
      <OpenTable
        status={selectedTableData?.status}
        tableId={selectedTableData?.table_id}
        isTableTaken={isTableTaken}
        onOpenTableClick={onOpenTableClick}
        onBackToChooseClick={onBackToChooseClick}
      />
    </>
  );
});
