import styled, { css } from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { Icon } from '../Icon';
import { Button } from '../Button';

export const ButtonStyled = styled(Button)`
  min-width: min-content;
  flex-direction: column;
  padding: 24px;

  ${({ theme }) => css`
    color: ${theme.text.primaryDefault};
  `}
`;

export const ButtonIcon = styled(Icon)`
  height: 48px;
  width: 48px;
`;

export const ButtonText = styled.p`
  margin-top: 16px;
  ${fontService.text({ size: 16, lineHeight: 125, weight: FontWeight.extraBold })};
  text-transform: uppercase;
`;
