import { put, call, takeEvery } from 'redux-saga/effects';
import { ActionType } from 'typesafe-actions';
import { NotificationManager } from 'react-notifications';
import * as Sentry from '@sentry/react';

import { TableService } from 'services/TableService';

import {
  saveTablesToStore,
  getTablesFailure,
  getTablesSuccess,
  getTableRequest,
  getTableSuccess,
  getTableFailure,
  saveSelectedTableToStore,
} from '../reducers';
import { ACTIONS } from '../constants';

function* getTablesSaga() {
  try {
    const { data } = yield call(() => TableService.getTables());

    yield put(getTablesSuccess());

    if (!data.length) {
      NotificationManager.warning(`No tables`, 'Info');
      return;
    }

    yield put(saveTablesToStore({ tablesData: data }));
  } catch (err) {
    Sentry.captureException(err);
    yield put(getTablesFailure(err as Error));
  }
}

function* getTableSaga({ payload: { id } }: ActionType<typeof getTableRequest>) {
  try {
    const { data } = yield call(() => TableService.getTable(id));

    yield put(getTableSuccess());

    yield put(saveSelectedTableToStore({ selectedTableData: data }));
  } catch (err) {
    Sentry.captureException(err);
    yield put(getTableFailure(err as Error));
  }
}

export function* tableSaga() {
  yield takeEvery(ACTIONS.GET_TABLES_REQUEST, getTablesSaga);
  yield takeEvery(ACTIONS.GET_TABLE_REQUEST, getTableSaga);
}
