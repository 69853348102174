import { IconNames } from 'components/atoms';
import React, { useMemo } from 'react';
import { ToastTypes } from 'types';
import { Wrapper, AlertIcon, Message } from './styles';

export enum AlertAssignments {
  Icon = 'Icon',
  Notice = 'Notice',
}

export interface IProps {
  variant?: ToastTypes;
  showIcon?: boolean;
  children?: string;
  assignment?: AlertAssignments;
}

export const Alert = ({
  variant = ToastTypes.SUCCESS,
  showIcon = false,
  children = '',
  assignment = AlertAssignments.Notice,
}: IProps) => {
  const iconName: IconNames | undefined = useMemo(() => {
    if (variant === ToastTypes.SUCCESS) {
      return IconNames.Warning;
    }

    if (variant === ToastTypes.WARNING) {
      return IconNames.Warning;
    }

    if (variant === ToastTypes.ERROR) {
      return IconNames.Warning;
    }
  }, [variant]);

  return (
    <Wrapper variant={variant} showIcon={showIcon} assignment={assignment}>
      {showIcon && iconName ? <AlertIcon name={iconName} /> : null}
      {children && <Message>{children}</Message>}
    </Wrapper>
  );
};
