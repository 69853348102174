import { TablesClosingStatus } from 'constants/tables';
import { SelectOptionType } from 'types';

export const tableClosingOptionsMock: SelectOptionType<TablesClosingStatus>[] = [
  {
    label: TablesClosingStatus.Inactive,
    value: TablesClosingStatus.Inactive,
  },
  {
    label: TablesClosingStatus.Maintenance,
    value: TablesClosingStatus.Maintenance,
  },
];
