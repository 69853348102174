import { AnyAction } from 'redux';
import { ICustomError } from 'types';
import { createAction } from 'typesafe-actions';

export const CLEAR_ERROR_BY_ACTION_TYPE = 'CLEAR_ERROR_BY_ACTION_TYPE';

export const clearErrorByActionType = createAction(
  CLEAR_ERROR_BY_ACTION_TYPE,
  (actionType: string) => actionType,
)();

interface IErrorReducerState {
  [actionType: string]: Error | ICustomError;
}

export const errorReducer = (
  state: IErrorReducerState = {},
  action: AnyAction,
): IErrorReducerState => {
  const { type, payload } = action;

  if (type === CLEAR_ERROR_BY_ACTION_TYPE) {
    return {
      ...state,
      [payload]: null,
    };
  }

  const matches = /(.*)_(REQUEST|FAILURE)/.exec(type);
  if (!matches) return state;

  const [, requestName, requestState] = matches;
  return {
    ...state,
    [requestName]: requestState === 'FAILURE' ? payload : null,
  };
};
