import React from 'react';
import { IconNames } from '../Icon';
import { ButtonStyled, IconStyled } from './styles';

export interface IProps {
  disabled?: boolean;
  iconName: IconNames;
  onClick?: () => void;
  className?: string;
}

export const ButtonRound = ({ disabled = false, iconName, onClick, className }: IProps) => (
  <ButtonStyled disabled={disabled} onClick={!disabled ? onClick : undefined} className={className}>
    <IconStyled name={iconName} />
  </ButtonStyled>
);
